import { apiSlice } from "../../app/api/apiSlice";

export const memberApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (payload) => ({
        url: "/member/register",
        method: "POST",
        body: { ...payload },
      }),
    }),
    getAllMembers: builder.query({
      query: () => ({
        url: "/admin/member",
        method: "GET",
      }),
    }),
    getAllPaginatedMembers: builder.query({
      query: (payload) => ({
        url: `/admin/member?page=${payload.page}&limit=${payload.limit}`,
        method: "GET",
      }),
    }),
  }),
});

export const { 
  useRegisterMutation, 
  useGetAllMembersQuery,
  useGetAllPaginatedMembersQuery,
} = memberApiSlice;
