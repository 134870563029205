import { current } from '@reduxjs/toolkit';
import { setUISelection } from '@testing-library/user-event/dist/cjs/document/UI.js';
import { useState } from 'react';

import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowRight } from 'react-icons/bs';

const MonthlyDonation = ({ data }) => {
  const slides = data;
  console.log(slides, 'slides');
  const reversedSlides = [...slides].reverse();
  const [currentIndex, setCurrentIndex] = useState(reversedSlides.length - 1);
  console.log(reversedSlides, 'reversed');
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide
      ? reversedSlides.length - 1
      : currentIndex - 1;

    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === reversedSlides.length - 1;
    const nextIndex = isLastSlide ? 0 : currentIndex + 1;

    setCurrentIndex(nextIndex);
  };

  return (
    <div>
      {slides && (
        <div className="flex justify-center items-center gap-4">
          <button className="text-2xl" onClick={goToPrevious}>
            {' '}
            <BsArrowLeft />{' '}
          </button>
          <div className="flex flex-col justify-center items-center mx-5">
            <h1 className="font-normal text-sm text-[#ACACAC]">
              Monthly donations
            </h1>
            <p className="text-2xl font-bold text-[#333333]">
              {reversedSlides[currentIndex]?.totalAmount}
            </p>
            <p className="font-normal text-sm text-[#ACACAC]">
              {new Date(
                reversedSlides[currentIndex]?.year,
                reversedSlides[currentIndex].month - 1
              ).toLocaleString('default', { month: 'long', year: 'numeric' })}
            </p>
          </div>
          <button className="text-2xl" onClick={goToNext}>
            {' '}
            <BsArrowRight />{' '}
          </button>
        </div>
      )}
    </div>
  );
};

export default MonthlyDonation;
