import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import { motion } from 'framer-motion';
import { useGetAllProjectQuery } from '../home/homeApiSlice';
import { Spinner } from '@chakra-ui/react';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const Announcement = () => {
  const {
    data: announcements,
    error,
    isLoading,
  } = useGetAllProjectQuery({ type: 'announcement' });
  const textEffect = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: { duration: 1.2, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const totalPages = Math.ceil(announcements?.projects.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(
    startIndex + itemsPerPage,
    announcements?.projects.length
  );
  const currentAnnouncements = announcements?.projects.slice(
    startIndex,
    endIndex
  );
  const goToPage = page => {
    setCurrentPage(Math.max(1, Math.min(page, totalPages)));
  };

  return (
    <>
      <div className="bg-[#0F1320] min-h-[100px] min-w-screen"></div>
      <div className="bg-[#F0F0F0]">
      <div className='ml-4 lg:ml-8 mt-8 flex flex-row'>
        <GoChevronLeft className='text-yellow-500 mt-1'/>
        <a href='/'>
          <button className='text-yellow-500 font-bold text-md'>Back to Home</button> 
        </a>
      </div>
        <motion.div
          variants={textEffect}
          initial={'offscreen'}
          whileInView={'onscreen'}
          viewport={{ once: true }}
          className="max-w-7xl mx-auto w-screen min-h-screen"
        >
          <h1 className="flex justify-center mt-8 py-6 text-5xl text-black font-extrabold px-4">
            Announcements
          </h1>
          <div className="flex flex-col xl:flex-row xl:space-x-4 px-4">
            <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
            <p className="flex justify-center text-md font-light py-8 text-center lg:px-4">
              A Space to find out what is going on around us. We will keep you
              updated on the latest news.
            </p>
            <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
          </div>

          {isLoading ? (
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Spinner
                size={'xl'}
                thickness="4px"
                emptyColor="gray.200"
                color="blue.500"
              />
            </div>
          ) : announcements?.projects.length === 0 ? (
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                fontSize: '50px',
              }}
            >
              No Announcements
            </div>
          ) : (
            currentAnnouncements.map((announcement, index) => {
              const formattedDate = announcement?.date?.split('T')[0];
              const date = new Date(formattedDate).toLocaleString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              });
              return (
                <motion.div
                  variants={textEffect}
                  initial={'offscreen'}
                  whileInView={'onscreen'}
                  viewport={{ once: true }}
                  key={index}
                  className="py-8 xl:py-12"
                >
                  <Link
                    to={`/announcement/announcementDetail/${
                      index + startIndex
                    }`}
                    className="cursor-pointer"
                    state={announcements}
                    onClick={scrollToTop}
                  >
                    <div className="flex flex-col xl:flex-row xl:space-x-8 space-y-4 xl:space-y-0 px-4 xl:px-0">
                      <div className="space-y-4 flex-1">
                        <p className="text-3xl font-bold">
                          {announcement.projectName}
                        </p>
                        <p className="text-md text-[#8A8A8A]">{date}</p>
                        <p className="text-md text-[#8A8A8A]">
                          {announcement.description}
                        </p>
                        <p>Read More</p>
                      </div>
                      <div className="w-full xl:w-1/2 h-[300px] flex items-center justify-center">
                        <img
                          src={
                            announcement.images[0]?.url ||
                            'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg'
                          }
                          alt={`Project ${index}`}
                          className="max-w-full max-h-full object-contain"
                        />
                      </div>
                    </div>
                  </Link>
                  <hr className="hidden xl:flex border-black border-1/2 w-full my-auto mt-8 xl:mt-16"></hr>
                </motion.div>
              );
            })
          )}
          {announcements?.projects.length === 0 ? null : (
            <motion.div className="flex justify-center mt-4 mb-16">
              <button
                onClick={() => goToPage(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-1 mr-2 "
              >
                <GoChevronLeft />
              </button>
              <p className="text-sm">
                Page {currentPage} of {totalPages}
              </p>
              <button
                onClick={() => goToPage(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-3 py-1 ml-2 "
              >
                <GoChevronRight />
              </button>
            </motion.div>
          )}
        </motion.div>
      </div>
    </>
  );
};

export default Announcement;
