import { useSelector } from 'react-redux';
import React, { createContext, useContext, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

// Chakra UI
import { Button } from '@chakra-ui/react';

// Logo
import { BsArrowBarLeft, BsArrowBarRight } from 'react-icons/bs';
import { ArrowForwardIcon } from '@chakra-ui/icons';

// API
import { useSendLogoutMutation } from '../features/auth/authApiSlice';

// To convey the expended state to the children
const DashSidebarContext = createContext();

const DashSidebar = ({ children }) => {
  const [expended, setExpended] = useState(true);
  const navigate = useNavigate();
  const token = useSelector(state => state.auth.token);

  const { pathname } = useLocation();

  const [sendLogout, { isLoading, isSuccess, isError, error }] =
    useSendLogoutMutation();

  const onLogoutClicked = async () => {
    await sendLogout();
    navigate('/login');
  };

  return (
    <aside className="h-screen fixed">
      <nav className="h-full flex flex-col bg-white border-r shadow-sm">
        <div className="p-4 pb-2 flex justify-between items-center">
          <Link to={'/dash'}>
            <div className="flex items-center">
              <img
                src="/navlogo.png"
                alt="Logo"
                className={`overflow-hidden transition-all ${
                  expended ? 'w-16' : 'w-0'
                }`}
              />
              {expended && <h1 className="font-medium text-2xl">Dashboard</h1>}
            </div>
          </Link>
          {false && (
            <button
              onClick={() => setExpended(!expended)}
              className="p-1.5 rounded-lg  hover:bg-gray-100 text-3xl"
            >
              {expended ? <BsArrowBarLeft /> : <BsArrowBarRight />}
            </button>
          )}
        </div>

        <DashSidebarContext.Provider value={{ expended, pathname }}>
          <ul className="flex-1 px-3">{children}</ul>
        </DashSidebarContext.Provider>

        <div className="p-4 pb-2 flex justify-center items-center">
          {token && (
            <Button
              rightIcon={<ArrowForwardIcon />}
              variant="outline"
              isLoading={isLoading}
              onClick={onLogoutClicked}
              className={'text-3xl'}
            >
              Logout
            </Button>
          )}
        </div>
      </nav>
    </aside>
  );
};

export function DashSidebarItem({ icon, text, active, alert, path }) {
  const { expended, pathname } = useContext(DashSidebarContext);

  return (
    <li
      className={`relative flex items-center py-2 px-3 pl-5 my-1 font-medium rounded-md cursor-pointer transition-colors group ${
        path === pathname
          ? 'bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800'
          : 'hover:bg-indigo-50 text-gray-600'
      }`}
    >
      <Link className="flex" to={path}>
        <span className="text-2xl">{icon}</span>
        <span
          className={`overflow-hidden transition-all ${
            expended ? 'w-52 ml-3' : 'w-0'
          }`}
        >
          {text}
        </span>
        {!expended && (
          <div
            className={`absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}
          >
            {text}
          </div>
        )}
      </Link>
    </li>
  );
}

export default DashSidebar;
