import { apiSlice } from "../../app/api/apiSlice";

export const opportunityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteOpportunity: builder.mutation({
      query: (payload) => ({
        url: `/opportunity/${payload.id}`,
        method: "DELETE",
      }),
      invalidatesTags:["opportunity"]
    }),
    updateOpportunity: builder.mutation({
      query: (payload) => ({
        url: `/opportunity/${payload.id}`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags:["opportunity"]
    }),
    getOpportunities: builder.query({
      query: (payload) => ({
        url: `/opportunity?page=${payload.page}&limit=${payload.limit}`,
        method: "GET",
      }),
      providesTags:["opportunity"]
    }),
    addOpportunity: builder.mutation({
      query: (payload) => ({
        url: "/opportunity",
        method: "POST",
        body: payload,
      }),
      invalidatesTags:["opportunity"]
    }),
    getAllOpportunities: builder.query({
      query: (payload) => ({
        url: `/opportunity`,
        method: "GET",
      }),
      providesTags:["opportunity"]
    }),
  }),
});

export const { 
    useDeleteOpportunityMutation,
    useUpdateOpportunityMutation,
    useGetOpportunitiesQuery,
    useGetAllOpportunitiesQuery,
    useAddOpportunityMutation,
} = opportunityApiSlice;