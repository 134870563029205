import { apiSlice } from '../../app/api/apiSlice';

export const homeApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    statistics: builder.query({
      query: () => ({
        url: '/member/status',
        method: 'GET',
      }),
      providesTags: ['statistic'],
    }),
    getAllProject: builder.query({
      query: payload => ({
        url: `/project/?page=${payload.page}&limit=${payload.limit}&type=${payload.type}`,
        method: 'GET',
      }),
      providesTags: ['project'],
    }),
  }),
});

export const { useStatisticsQuery, useGetAllProjectQuery } = homeApiSlice;
