import React from 'react';
import Projects from '../features/dashboard/Projects';

const DashboardProjectPage = () => {
  return (
    <div className="h-screen">
      <Projects />
    </div>
  );
};

export default DashboardProjectPage;
