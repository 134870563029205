import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

//RTK
import { useSelector, useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';

// RTK Query
import { useLoginMutation, useRefreshMutation } from './authApiSlice';

//Initial Form State
let initialState = {
  username: '',
  password: '',
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Toast
  const toast = useToast();

  //RTK Query
  const [login, { isLoading }] = useLoginMutation();

  // Form
  const [formData, setFormData] = useState(initialState);
  const handleChange = e =>
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));

  // Form Submit Handler
  const onHandleSubmit = async e => {
    e.preventDefault();
    try {
      //Call API
      const { accessToken } = await login(formData).unwrap();
      //Update Store
      dispatch(setCredentials({ accessToken }));
      //Update Form
      setFormData(initialState);
      navigate('/dash');
    } catch (error) {
      let errorMessage;

      if (!error.status) {
        errorMessage = 'No Server Response';
      } else if (error.status === 400) {
        errorMessage = 'Missing Username or Password';
      } else if (error.status === 401) {
        errorMessage = 'Incorrect username or password';
      } else {
        errorMessage = error.data?.message;
      }

      // if(error.data.message === "Unauthorized"){
      //   errorMessage = "Incorrect username or password"
      // }else{
      //   errorMessage = error.data.message;
      // }

      toast({
        title: errorMessage,
        status: 'error',
        isClosable: false,
        duration: 1500,
      });
    }
  };

  return (
    <div className="w-screen min-h-screen bg-[#0f1320]">
      <Box
        maxW="400px"
        p={4} 
        m="auto"
        mt="100"
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="lg"
        bg="white"
      >
        <form onSubmit={onHandleSubmit}>
          <FormControl id="email" mb={4}>
            <FormLabel>Username</FormLabel>
            <Input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </FormControl>
          <FormControl id="password" mb={4}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </FormControl>
          <Button type="submit" colorScheme="blue" isLoading={isLoading}>
            Login
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default Login;
