import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Thead,
  Th,
  Td,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  useAddProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
} from './dashApiSlice';
import { useGetAllProjectQuery } from '../home/homeApiSlice';
import { HiDotsVertical } from 'react-icons/hi';
import Pagination from './Pagination';
import Editor from '../../components/Editor';
import { Form, Link } from 'react-router-dom';
import AddAnnouncement from './AddAnnouncement';
import { PostImageUploaders } from '../../components/ImageUploads';
const Announcement = () => {
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({});
  const toast = useToast();
  const [limit, setLimit] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [announcementName, setAnnouncementName] = useState('');
  const [date, setDate] = useState('');
  const [content, setContent] = useState('');
  const [images, setImages] = useState(null);
  const [preview, setPreview] = useState(null);
  const [addAnnouncement, { isLoading: isError }] = useAddProjectMutation();
  const [publicIds, setPublicIds] = useState([]);
  const {
    isOpen: isAlertOpen,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const {
    isOpen: isModelOpen,
    onOpen: onModelOpen,
    onClose: onModelClose,
  } = useDisclosure();
  const {
    data: announcements,
    error,
    isLoading,
  } = useGetAllProjectQuery(
    { limit, page: currentPage, type: 'announcement' },
    { pollingInterval: 5000 }
  );
  const [deleteAnnouncement, { isLoading: isDeleting, error: deleteError }] =
    useDeleteProjectMutation();
  const [updateAnnouncement, { isLoading: isUpdating, isError: updateError }] =
    useUpdateProjectMutation();
  if (announcements?.projects.result.length === 0 && !isLoading) {
    if (announcements?.projects.pageCount) {
      if (
        announcements?.projects.previous.page === 0 ||
        announcements?.projects.previous.page === null ||
        announcements?.projects.previous.page === undefined
      ) {
        setCurrentPage(announcements?.projects.previous.page);
      } else {
        setCurrentPage(1);
      }
    }
  }
  const handleImageChange = (file, deleteIds) => {
    const files = Array.from(file);
    if (files.length > 2) {
      toast({
        title: 'you can only upload up to 2 images',
        status: 'error',
        isClosable: false,
        duration: 3000,
      });
      return;
    }
    if (deleteIds.length > 0) {
      setPublicIds(deleteIds);
    }
    setImages(files);
    files.forEach(file => {
      const previewUrls = files.map(file => URL.createObjectURL(file));
      setPreview(previewUrls);
    });
  };

  const onHandleDelete = async () => {
    if (selectedAnnouncement) {
      try {
        await deleteAnnouncement({ id: selectedAnnouncement._id }).unwrap();
        toast({
          title: 'Project Deletion',
          description: 'Project deleted successfully',
          status: 'success',
          isClosable: true,
          duration: 3000,
        });
        onClose();
        onCloseAlert();
      } catch (err) {
        toast({
          title: 'Project Deletion',
          description: 'The Operation has failed, please try again later',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
        onCloseAlert();
      }
    }
  };

  const handleThings = e => {
    localStorage.setItem('projectName', announcementName);
    localStorage.setItem('date', date);
    localStorage.setItem('content', content);
    localStorage.setItem('preview', JSON.stringify(preview));
  };
  const onHandleSubmit = async e => {
    e.preventDefault(e);
    if (!announcementName && !date && !content) {
      return toast({
        title: 'Form Validation',
        status: 'error',
        description: 'Please fill in all the required fields',
        isClosable: false,
        duration: 2500,
      });
    }
    if (images && images.length > 2) {
      toast({
        title: 'you can only upload up to 2 images',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const drawerObject = {};
    const formData = new FormData();
    formData.append('projectName', announcementName);
    drawerObject.announcementName = announcementName;
    formData.append('date', date);
    drawerObject.date = date;
    formData.append('content', content);
    drawerObject.content = content;
    formData.append('id', selectedAnnouncement._id);
    formData.append('toDeleteIds', publicIds);
    images?.forEach(image => {
      formData.append('images', image);
    });

    try {
      await updateAnnouncement(formData).unwrap();
      onModelClose();
      setSelectedAnnouncement(drawerObject);
      onClose();
      toast({
        title: 'Announcement Edition',
        description: 'Successfully updated announcement details',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
      setPublicIds([]);
      localStorage.removeItem('projectName');
      localStorage.removeItem('date');
      localStorage.removeItem('content');
      localStorage.removeItem('preview');
    } catch (err) {
      toast({
        title: 'Project Edition',
        description: 'The Operation has failed, please try again later',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };
  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        Error while loading the table
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="w-4/5 mt-10 p-5 border rounded-md">
        <div className="flex justify-between mb-5 items-center border-b-2 p-5">
          <h1 className="font-medium text-lg">Announcements</h1>
          <AddAnnouncement />
        </div>
        <TableContainer>
          <Table size={'sm'}>
            <Thead>
              <Tr>
                <Th>Announcement Name</Th>
                <Th>DAte</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr style={{ height: '50px' }}>
                  <Td colSpan={6} style={{ textAlign: 'center' }}>
                    <Spinner />
                  </Td>
                </Tr>
              ) : announcements?.projects.result.length === 0 ? (
                <Tr style={{ height: '50px' }}>
                  <Td colSpan={6} style={{ textAlign: 'center' }}>
                    No Content
                  </Td>
                </Tr>
              ) : (
                announcements?.projects.result.map(announcement => {
                  const formattedDate = announcement?.date?.split('T')[0];
                  const date = new Date(formattedDate).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  });
                  return (
                    <Tr key={announcement._id}>
                      <Td>{announcement.projectName}</Td>
                      <Td>{date}</Td>
                      <Td>
                        <Button
                          onClick={() => {
                            setSelectedAnnouncement(announcement);
                            setContent(announcement.content);
                            setAnnouncementName(announcement.projectName);
                            setPreview(announcement.images);
                            setDate(
                              new Date(announcement.date)
                                .toISOString()
                                .substring(0, 10)
                            );
                            onOpen();
                          }}
                        >
                          <HiDotsVertical />
                        </Button>
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </TableContainer>

        {announcements?.projects.result.length !== 0 && !isLoading && (
          <div class={'flex justify-end'}>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageCount={announcements?.projects.pageCount}
            />
          </div>
        )}
      </div>

      <Drawer isOpen={isOpen} onClose={onClose} size={'sm'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Announcement Details</DrawerHeader>
          <DrawerBody>
            <div>
              <div className="mb-5">
                <h1 className="text-[#585858] font-semibold mb-1">
                  Announcement Name
                </h1>
                <p className="font-semibold">
                  {selectedAnnouncement?.projectName}
                </p>
              </div>
              <div className="mb-5">
                <h1 className="text-[#585858] font-semibold mb-1">Date</h1>
                <p className="font-semibold">
                  {new Date(
                    selectedAnnouncement?.date?.split('T')[0]
                  ).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </p>
              </div>
              <div className="mb-5">
                <h1 className="text-[#585858] font-semibold  mb-1">
                  Description
                </h1>
                <div
                  className="ql-editor border"
                  variant="h5regular"
                  id={'content'}
                  dangerouslySetInnerHTML={{
                    __html: selectedAnnouncement.content,
                  }}
                ></div>
              </div>
              <div className="mb-5">
                <h1 className="text-[#585858] font-semibold  mb-1">Image</h1>
                {selectedAnnouncement?.images?.map((image, index) => {
                  return (
                    <>
                      <img
                        src={
                          image?.url ||
                          'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg'
                        }
                        className="mb-5"
                        alt="Opportunity Image"
                      />
                    </>
                  );
                })}
              </div>
            </div>
          </DrawerBody>
          <DrawerFooter
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button variant="outline" mr={3} onClick={onModelOpen}>
              Edit
            </Button>
            <Button colorScheme="blue" onClick={onOpenAlert}>
              Delete
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={'lg'} fontWeight={'bold'}>
              Delete Announcement
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Cancel
              </Button>
              <Button
                colorScheme={'red'}
                onClick={onHandleDelete}
                ml={3}
                isLoading={isDeleting}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isModelOpen}
        onClose={onModelClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Announcement</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form>
              <FormControl id="announcementName" mb={4} isRequired>
                <FormLabel>Announcement Name</FormLabel>
                <Input
                  type="text"
                  name="announcementName"
                  value={announcementName}
                  onChange={e => {
                    setAnnouncementName(e.target.value);
                  }}
                  style={{ border: '0.5px solid grey' }}
                />
              </FormControl>
              <FormControl id="date" mb={4} isRequired>
                <FormLabel>Date</FormLabel>
                <Input
                  type="date"
                  name="date"
                  value={date}
                  onChange={e => {
                    setDate(e.target.value);
                  }}
                  style={{ border: '0.5px solid grey' }}
                />
              </FormControl>
              <FormControl id="content" mb={4}>
                <FormLabel>Description</FormLabel>
                <Editor value={content} onChange={setContent} />
              </FormControl>
              <PostImageUploaders
                handleImageChange={handleImageChange}
                preview={preview}
                setPreview={setPreview}
              />
              <Button mr={3} onClick={onModelClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="yellow"
                bgColor="#FDBC06"
                onClick={onHandleSubmit}
                isLoading={isUpdating}
              >
                Save
              </Button>
              <Button>
                <Link
                  to="/preview/announcement"
                  target="_blank"
                  onClick={handleThings}
                >
                  Preview
                </Link>
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Announcement;
