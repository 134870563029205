import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ProjectDetail from '../features/project/projectDetail/ProjectDetail';
const Preview = () => {
  const { type } = useParams();
  const [date, setDate] = useState(null);
  const project = {
    projectName: localStorage.getItem('projectName'),
    date: localStorage.getItem('date'),
    content: localStorage.getItem('content'),
    images: JSON.parse(localStorage.getItem('preview')),
  };
  const textEffect = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: { duration: 1.2, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };
  useEffect(() => {
    if (project.date) {
      const formattedDate = project.date.split('T')[0];
      const date = new Date(formattedDate).toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
      setDate(date);
    }
  }, []);
  return (
    <>
      <div className="bg-[#0F1320] min-h-[100px] min-w-screen"></div>
      <div className="bg-[#F0F0F0]">
        <motion.div
          variants={textEffect}
          initial={'offscreen'}
          whileInView={'onscreen'}
          viewport={{ once: true }}
          className="max-w-7xl mx-auto w-screen min-h-screen"
        >
          {type === 'project' ? (
            <h1 className="flex justify-center mt-8 py-6 text-5xl text-black font-extrabold">
              Projects
            </h1>
          ) : (
            <h1 className="flex justify-center mt-8 py-6 text-5xl text-black font-extrabold">
              Announcements
            </h1>
          )}
          {type === 'project' ? (
            <>
              <div className="flex flex-col xl:flex-row xl:space-x-4 xl:mb-8">
                <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
                <p className="flex justify-center text-md font-bold py-8 text-center xl:text-start lg:px-4">
                  Browse through our meaningful social projects aimed at making
                  a difference in the lives of individuals and communities.
                </p>
                <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
              </div>
            </>
          ) : (
            <div className="flex flex-col xl:flex-row xl:space-x-4 xl:mb-8">
              <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
              <p className="flex justify-center text-md font-bold py-8 text-center xl:text-start lg:px-4">
                A Space to find out what is going on around us. We will keep you
                updated on the latest news.
              </p>
              <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
            </div>
          )}

          <motion.div
            variants={textEffect}
            initial={'offscreen'}
            whileInView={'onscreen'}
            viewport={{ once: true }}
            className="space-y-12 w-full py-8 xl:py-16 mx-auto text-center px-4 xl:px-0"
          >
            <h1 className="font-bold text-4xl">{project?.projectName}</h1>
            <p className="font-bold text-[#8A8A8A]">{date}</p>
            <motion.p
              variants={textEffect}
              initial={'offscreen'}
              whileInView={'onscreen'}
              viewport={{ once: true }}
              className="text-[#8A8A8A] text-start w-full xl:w-[70%] mx-auto px-4 xl:px-0"
            >
              <div
                className="ql-editor"
                variant="h5regular"
                id={'content'}
                dangerouslySetInnerHTML={{ __html: project?.content }}
              ></div>
            </motion.p>
            {project.images ? (
              project?.images[1] || project?.images[1]?.url ? (
                <motion.div
                  variants={textEffect}
                  initial={'offscreen'}
                  whileInView={'onscreen'}
                  viewport={{ once: true }}
                  className="flex flex-col xl:flex-row items-center justify-center space-x-0 xl:space-x-8 space-y-8 xl:space-y-0"
                >
                  <img
                    src={project?.images[0].url || project?.images[0]}
                    width={440}
                  />
                  <img
                    src={project?.images[1].url || project?.images[1]}
                    width={500}
                  />
                </motion.div>
              ) : (
                <motion.div
                  variants={textEffect}
                  initial={'offscreen'}
                  whileInView={'onscreen'}
                  viewport={{ once: true }}
                  className="flex items-center justify-center"
                >
                  <img
                    src={
                      (project.images && project?.images[0]?.url) ||
                      project?.images[0]
                    }
                    className="mx-auto w-full xl:w-2/3"
                  />
                </motion.div>
              )
            ) : (
              <></>
            )}
          </motion.div>
        </motion.div>
      </div>
    </>
  );
};
export default Preview;
