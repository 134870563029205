
import { useState, useEffect } from 'react';
import { useGetAllMembersQuery } from '../features/member/memberApiSlice';
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { Spinner } from '@chakra-ui/react'
import { motion } from "framer-motion";

const MemberDisplay = () => {
  const textEffect = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: { duration: 1.0, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };
  const { data: members, error, isLoading } = useGetAllMembersQuery({}, {
    pollingInterval: 5000
  });
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 30;

  useEffect(() => {
    if (error) {
      console.error('An error occurred:', error);
    }
  }, [error]);

  let content;

  if (isLoading) {
    content = (
      <div className='flex justify-center content-center'>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='yellow.200'
          color='yellow.500'
          size='xl'
        />
      </div>
    );
  } else if (members && members.members && members.members.length > 0) {
    // Calculate pagination logic
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentMembers = members.members.slice(indexOfFirstCard, indexOfLastCard);

    // Render cards for the current page
    content = (
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4'>
        {currentMembers.map(member => (
          <div key={member.id} className="bg-white px-4 py-2 rounded-md mb-4">
            <p className="text-black text-[14px]">{member.name}</p>
            <p className="text-[#989898] text-[12px]">{member.email}</p>
            <p className="text-[#989898] text-[12px]">{member.dzongkhag}</p>
            <p className="text-[#989898] text-[12px]">{member.country}</p>
          </div>
        ))}
      </div>
    );
  } else {
    content = (
      <div className='flex justify-center content-center text-2xl text-gray-500'>
        No Members
      </div>
    );
  }

  const totalPages = Math.ceil(members?.members?.length / cardsPerPage);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <>
      <motion.div className='px-8'>
        <motion.div
          variants={textEffect}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true }}
          className='flex flex-col xl:flex-row xl:space-x-4'
        >
          <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
          <p className='text-4xl text-center xl:text-start font-bold text-[#484848] py-8 xl:w-[40%]'>Member and the people community</p>
          <p className='text-sm text-center xl:text-start font-light text-[#484848] py-8 xl:w-[50%]'>Members are the lifeblood of our community, contributing their unique skills, perspectives, and passions to enrich our collective journey. Together, we form a vibrant and inclusive community dedicated to collaboration, growth, and making a positive impact in the world.</p>
          <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
        </motion.div>
        <div className='mt-16'>
          {content}
        </div>
        {members && members.members && members.members.length > 0 && (
          <div className="flex justify-center mt-4 text-black">
            <button onClick={prevPage} disabled={currentPage === 1}><GoChevronLeft /></button>
            <span className="mx-4">{`Page ${currentPage} of ${totalPages}`}</span>
            <button onClick={nextPage} disabled={currentPage === totalPages}><GoChevronRight /></button>
          </div>
        )}
      </motion.div>
    </>
  );
};

export default MemberDisplay;
