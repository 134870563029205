import React from 'react';
import MemberRegistrationRequestList from './MemberRegistrationRequestList';
import Pagination from './Pagination';

function Dashboard() {
  return (
    <div className='h-screen'>
        <MemberRegistrationRequestList/>
    </div>
  )
}

export default Dashboard