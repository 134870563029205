import React from 'react';
import { useToast } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
} from '@chakra-ui/react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { FormControl, FormLabel, Input, Select } from '@chakra-ui/react';

import Pagination from './Pagination';
import AddDonation from './AddDonation';

import { FaMoneyBill } from 'react-icons/fa';
import { HiDotsVertical } from 'react-icons/hi';
import { useDisclosure } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { Spinner } from '@chakra-ui/react';

import {
  useGetDonationsQuery,
  useDeleteDonationMutation,
  useUpdateDonationMutation,
} from './dashApiSlice';

import MonthlyDonation from './MonthlyDonation';

const Donation = () => {
  const [selectedDonations, setSelectedDonations] = useState({});
  const [formData, setFormData] = useState({});
  const [monthlyDonation, setMonthlyDonation] = useState([]);

  const toast = useToast();

  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  // API
  const [updateDonation, { isLoading: isUpdating, isError: updateError }] =
    useUpdateDonationMutation();
  const [deleteDonation, { isLoading: isDeleting, isError: deleteError }] =
    useDeleteDonationMutation();
  const {
    data: donations,
    error,
    isLoading,
  } = useGetDonationsQuery(
    { limit, page: currentPage },
    { pollingInterval: 1000 }
  );
  // Drawer
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Model
  const {
    isOpen: isModelOpen,
    onOpen: onModelOpen,
    onClose: onModelClose,
  } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  // Alert
  const {
    isOpen: isAlertOpen,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();
  const cancelRef = React.useRef();

  if (donations?.donations.result.length === 0 && !isLoading) {
    if (donations?.donations.pageCount) {
      if (
        donations?.donations.previous.page === 0 ||
        donations?.donations.previous.page === null ||
        donations?.donations.previous.page === undefined
      ) {
        setCurrentPage(donations?.donations.previous.page);
      } else {
        setCurrentPage(1);
      }
    }
  }

  const onHandleDelete = async () => {
    if (selectedDonations) {
      try {
        await deleteDonation({ id: selectedDonations._id }).unwrap();
        toast({
          title: 'Membership',
          description: 'Member deleted successfully',
          status: 'success',
          duration: 8000,
          isClosable: true,
        });
        onClose();
        onCloseAlert();
      } catch (error) {
        toast({
          title: 'Membership',
          description: 'The Operation has failed, please try again later.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
        onCloseAlert();
      }
    }
  };

  const handleChange = e => {
    if (e.target) {
      setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const onHandleSubmit = async e => {
    e.preventDefault(e);

    const { amount, date, description } = formData;

    if (!amount || !date) {
      return toast({
        title: 'Form Validation',
        status: 'error',
        description: 'Please fill all the required fields',
        isClosable: false,
        duration: 2500,
      });
    }

    if (parseInt(amount) === 0) {
      return toast({
        title: 'Form Validation',
        status: 'error',
        description: 'Amount cannot be zero',
        isClosable: false,
        duration: 2500,
      });
    }

    try {
      await updateDonation({
        id: selectedDonations._id,
        amount,
        date,
        description,
      }).unwrap();
      onModelClose();
      setSelectedDonations(formData);
      toast({
        title: 'Donation Updation',
        description: 'Successfully updated the donation.',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
    } catch (error) {
      toast({
        title: 'Donation Updation',
        status: 'error',
        description:
          'An Error Occurred While sending the request. Please try again after some time.',
        isClosable: false,
        duration: 2500,
      });
    }
  };

  useEffect(() => {
    if (donations && donations?.monthlyDonation) {
      setMonthlyDonation(donations?.monthlyDonation);
    }
  }, [donations]);

  // if (isLoading)
  //   return (
  //     <div className="flex justify-center items-center h-screen">
  //       <Spinner />
  //     </div>
  //   );

  if (error)
    return (
      <div className="flex justify-center items-center h-screen">
        Error: {error.message}
      </div>
    );

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex border rounded-md w-4/5 mt-10 justify-center items-center gap-4 py-8">
        <div className="flex justify-center items-center gap-2 border-r-2 px-10">
          <div className="text-4xl p-4 border rounded-full">
            <FaMoneyBill />
          </div>
          <div>
            <h1 className="font-normal text-sm text-[#ACACAC]">
              Total Donation
            </h1>
            <p className="text-2xl font-bold text-[#333333]">
              {isLoading ? <Spinner /> : donations?.totalDonation}
            </p>
          </div>
        </div>

        <div className="px-10">
          {isLoading ? (
            <div>
              <Spinner />
            </div>
          ) : monthlyDonation.length !== 0 ? (
            <MonthlyDonation data={monthlyDonation} />
          ) : (
            <div>No status for monthly donation</div>
          )}
        </div>
      </div>

      <div className="w-4/5 mt-10 p-5 border rounded-md">
        <div className="flex justify-between mb-5 items-center border-b-2 p-5">
          <h1 className="font-medium text-lg">Donation History</h1>
          <AddDonation />
        </div>

        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Amount</Th>
                <Th>Date</Th>
                <Th>Description</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr style={{ height: '50px' }}>
                  <Td colSpan={6} style={{ textAlign: 'center' }}>
                    <Spinner />
                  </Td>
                </Tr>
              ) : donations?.donations.result.length === 0 ? (
                <Tr style={{ height: '50px' }}>
                  <Td colSpan={6} style={{ textAlign: 'center' }}>
                    No Content
                  </Td>
                </Tr>
              ) : (
                donations?.donations.result.map(donation => {
                  const formattedDate = donation?.date?.split('T')[0];
                  const date = new Date(formattedDate).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  });
                  return (
                    <Tr key={donation._id}>
                      <Td>{donation.amount}</Td>
                      <Td>{date}</Td>
                      <Td>
                        {donation.description.length > 20
                          ? donation.description.slice(0, 20) + '...'
                          : donation.description}
                      </Td>
                      <Td>
                        <Button
                          onClick={() => {
                            setSelectedDonations(donation);
                            onOpen();
                            setFormData(donation);
                          }}
                        >
                          <HiDotsVertical />
                        </Button>
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </TableContainer>

        {donations?.donations.result.length !== 0 && !isLoading && (
          <div class={'flex justify-end'}>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageCount={donations?.donations.pageCount}
            />
          </div>
        )}
      </div>

      <Drawer isOpen={isOpen} onClose={onClose} size={'sm'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="2px" className="text-[#585858]">
            Donation Details
          </DrawerHeader>

          <DrawerBody>
            <div>
              <div className="mb-5">
                <h1 className="text-[#585858] font-semibold mb-1">Amount</h1>
                <p className="font-semibold">{selectedDonations?.amount}</p>
              </div>
              <div className="mb-5">
                <h1 className="text-[#585858] font-semibold mb-1">Date</h1>
                <p className="font-semibold">
                  {new Date(
                    selectedDonations?.date?.split('T')[0]
                  ).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </p>
              </div>

              {selectedDonations?.description && (
                <div className="mb-5">
                  <h1 className="text-[#585858] font-semibold mb-1">
                    Description
                  </h1>
                  <p className="border p-2">{selectedDonations?.description}</p>
                </div>
              )}
            </div>
          </DrawerBody>

          <DrawerFooter
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button variant="outline" mr={3} onClick={onModelOpen}>
              Edit
            </Button>
            <Button colorScheme="blue" onClick={onOpenAlert}>
              Delete
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Donation
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Cancel
              </Button>
              <Button
                colorScheme={'red'}
                onClick={onHandleDelete}
                ml={3}
                isLoading={isDeleting}
              >
                delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isModelOpen}
        onClose={onModelClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Donation</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form>
              <FormControl id="amount" mb={4} isRequired>
                <FormLabel>Amount</FormLabel>
                <Input
                  type="number"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey' }}
                  isDisabled={isUpdating}
                />
              </FormControl>

              {formData.date && (
                <FormControl id="date" mb={4} isRequired>
                  <FormLabel>Date</FormLabel>
                  <Input
                    type="date"
                    name="date"
                    value={new Date(formData.date)
                      .toISOString()
                      .substring(0, 10)}
                    onChange={handleChange}
                    style={{ border: '0.5px solid grey' }}
                    isDisabled={isUpdating}
                  />
                </FormControl>
              )}

              <FormControl id="description" mb={4}>
                <FormLabel>Description</FormLabel>
                <Input
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey' }}
                  isDisabled={isUpdating}
                />
              </FormControl>

              <Button mr={3} onClick={onModelClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="yellow"
                bgColor="#FDBC06"
                onClick={onHandleSubmit}
                isLoading={isUpdating}
              >
                Save
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Donation;
