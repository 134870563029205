import React from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { GoChevronLeft } from 'react-icons/go';


const OpportunityDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const opportunity = location.state;
  return (
    <>
      <div className="bg-[#0F1320] min-h-[100px] min-w-screen"></div>
      <div className="bg-[#F0F0F0] min-h-screen">
        <div className='ml-4 lg:ml-8 mt-4 flex flex-row'>
            <GoChevronLeft className='text-yellow-500 mt-1'/>
            <a href='/'>
              <button className='text-yellow-500 font-bold text-md'>Back to Home</button> 
            </a>
          </div>
        <div className="max-w-7xl mx-auto w-screen min-h-screen">
          <div>
            <h1 className="flex justify-center text-center mt-4 py-6 text-4xl xl:text-5xl text-black font-extrabold">
              Explore a career at Semthuen Zhidhey Zhabtok
            </h1>
            <div className="flex flex-col xl:flex-row xl:space-x-4">
              <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
              <p className="flex justify-center text-md font-light py-8 text-center px-4 xl:px-0">
                There are jobs-and then there are careers. The chance to do the
                kind of work that adds up to something meaningful. The
                opportunity to challenge yourself and learn new skills. The
                prospect of being surrounded by smart, ambitious. motivated
                people, day-in and day out. That's the kind of work you can
                expect to do at SZZ. Sound appealing? Come Join us.
              </p>
              <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
            </div>
          </div>

          <div className="space-y-8 w-full py-8 mx-auto text-center px-4 xl:px-0">
            <h1 className="font-bold text-4xl">{opportunity.title}</h1>
            <p className="text-[#8A8A8A]">
              {'posted ' +
                formatDistanceToNow(new Date(opportunity.date), {
                  addSuffix: true,
                })}
            </p>
            <div className="flex items-center justify-center">
              <img
                src={
                  opportunity.image.url ||
                  'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg'
                }
                className="w-auto h-full xl:h-[400px] px-0 xl:px-8 object-contain"
              />
            </div>

            <div
              className="ql-editor text-[#8A8A8A] text-start w-full xl:w-[70%] mx-auto px-4 xl:px-0 py-8"
              variant="h5regular"
              id={'content'}
              dangerouslySetInnerHTML={{ __html: opportunity.description }}
            ></div>

            <button className="py-2 px-4 bg-black text-white mt-4">
              <a href={opportunity.link} target="_blank">
                Apply Now
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpportunityDetail;
