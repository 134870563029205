import { useState } from 'react';

// Chakra UI
import { useDisclosure } from '@chakra-ui/react';
import { Button } from '@chakra-ui/button';
import { AddIcon } from '@chakra-ui/icons';
import { useToast, Textarea } from '@chakra-ui/react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { FormControl, FormLabel, Input, Select } from '@chakra-ui/react';

// API
import { useAddOpportunityMutation } from './OpportunityApiSlice.js';

// Custom UI
import { PostImageUploader } from '../../components/ImageUpload.jsx';
import Editor from '../../components/Editor.jsx';

// Initial State
let initialState = {
  title: '',
  image: '',
  description: '',
  link: '',
};

const AddOpportunity = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState(initialState);
  const [description, setDescription] = useState();

  // API
  const [AddOpportunity, { isLoading, isError, error }] =
    useAddOpportunityMutation();

  const handleChange = e => {
    if (e.target) {
      setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const onHandleSubmit = async e => {
    e.preventDefault(e);

    const { title, link, image } = formData;
    if (!title.trim() || !description.trim() || !link.trim()) {
      return toast({
        title: 'Form Validation',
        status: 'error',
        description: 'Please fill all the req uired fields',
        isClosable: false,
        duration: 2500,
      });
    }

    try {
      const data = new FormData();
      data.append('title', title);
      data.append('description', description);
      data.append('link', link);

      if (image) {
        data.append('image', image);
      }

      await AddOpportunity(data).unwrap();
      setFormData(initialState);
      setDescription('');
      onClose();
      toast({
        title: 'Oppotunity Registration',
        description: 'Successfully added the opportunity.',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
    } catch (error) {
      toast({
        title: 'Opportunity Registration',
        status: 'error',
        description:
          'An Error Occurred While sending the request. Please try again after some time.',
        isClosable: false,
        duration: 2500,
      });
    }
  };

  const handleImageChange = file => {
    setFormData(prev => ({ ...prev, image: file }));
  };

  return (
    <div>
      <Button leftIcon={<AddIcon />} variant="outline" onClick={onOpen}>
        Add Opportunity
      </Button>

      <Drawer isOpen={isOpen} onClose={onClose} size={'sm'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerHeader>
            <h1>Add Opportunity</h1>
          </DrawerHeader>

          <DrawerBody>
            <form>
              <FormControl id="title" mb={4} isRequired>
                <FormLabel>Title</FormLabel>
                <Input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey' }}
                  isDisabled={isLoading}
                />
              </FormControl>

              <FormControl id="description" mb={4} isRequired>
                <FormLabel>Description</FormLabel>
                {/*
                  <Textarea
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    style={{ border: '0.5px solid grey' }}
                  />
                */}
                <Editor value={description} onChange={setDescription} />
              </FormControl>

              <FormControl id="link" mb={4} isRequired>
                <FormLabel>Link</FormLabel>
                <Input
                  type="text"
                  name="link"
                  value={formData.link}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey' }}
                  isDisabled={isLoading}
                />
              </FormControl>
              <PostImageUploader handleImageChange={handleImageChange} />
              <Button
                type="submit"
                colorScheme="yellow"
                bgColor="#FDBC06"
                onClick={onHandleSubmit}
                className="w-full"
                isLoading={isLoading}
              >
                Add Opportunity
              </Button>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default AddOpportunity;
