import { apiSlice } from "../../app/api/apiSlice";

export const contactApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createContact: builder.mutation({
      query: (payload) => ({
        url: "/contact",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { 
    useCreateContactMutation,
} = contactApiSlice;