import React from 'react'
import DashboardOpportunity from "../features/opportunity/DashboardOpportunity"
const DashboardOpportunityPage = () => {
  return (
    <div className='h-screen'>
      <DashboardOpportunity/>
    </div>
  )
}

export default DashboardOpportunityPage