import {
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import { useRegisterMutation } from './memberApiSlice';
import { useNavigate } from 'react-router-dom';
import { Select } from '@chakra-ui/react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

let initialState = {
  name: '',
  email: '',
  phoneNumber: '',
  country: '',
  dzongkhag: '',
};

const Member = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [register, { isLoading }] = useRegisterMutation();
  const [formData, setFormData] = useState(initialState);

  const handleChange = e => {
    if (e.target) {
      setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    } else {
      // Check if the entered phone number has at least 4 or 5 digits
      if (e.length >= 4 || e.length >= 5) {
        setFormData(prev => ({ ...prev, phoneNumber: e }));
      } else {
        // Show error message if the entered phone number doesn't meet the criteria
        toast({
          title: 'Phone Number Validation',
          status: 'error',
          description:
            'Please enter a phone number with at least 4 or 5 digits.',
          isClosable: false,
          duration: 2500,
        });
      }
    }
  };

  const [showTerms, setShowTerms] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = e => {
    setIsChecked(e.target.checked);
  };

  const validateEmail = email => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onNextButtonClick = e => {
    e.preventDefault();
    const { name, email, phoneNumber, country, dzongkhag } = formData;

    // Check if all required fields are filled
    if (name && email && phoneNumber && country) {
      // Check if email is valid
      if (validateEmail(email)) {
        // If country is Bhutan, also check if Dzongkhag is selected
        if (country.toLowerCase() === 'bhutan' && !dzongkhag) {
          toast({
            title: 'Form Validation',
            status: 'error',
            description: 'Please select a Dzongkhag.',
            isClosable: false,
            duration: 2500,
          });
        } else {
          setShowTerms(true);
        }
      } else {
        toast({
          title: 'Form Validation',
          status: 'error',
          description: 'Please enter a valid email address.',
          isClosable: false,
          duration: 2500,
        });
      }
    } else {
      toast({
        title: 'Form Validation',
        status: 'error',
        description: 'Please fill in all the required fields.',
        isClosable: false,
        duration: 2500,
      });
    }
  };

  const onHandleSubmit = async e => {
    e.preventDefault();
    // Check if checkbox is checked
    if (!isChecked) {
      toast({
        title: 'Form Validation',
        status: 'error',
        description: 'Please agree to the terms and conditions.',
        isClosable: false,
        duration: 2500,
      });
      return; // Don't proceed if checkbox is not checked
    }

    try {
      const { name, email, phoneNumber, dzongkhag, country } = formData;
      const payload = {
        name,
        email,
        phoneNumber,
        dzongkhag,
        country,
      };

      // Perform your registration mutation
      const member = await register(payload).unwrap();

      // Reset form data
      setFormData(initialState);

      // Show success message
      toast({
        title: 'Member Registration',
        description:
          'Successfully sent membership registration request. Check your email for approval.',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });

      // Navigate to home page
      navigate('/');
    } catch (error) {
      console.error('Error occurred:', error);

      // Handle different error scenarios
      if (error.data?.message === 'Duplicate Phone Number') {
        setShowTerms(false);
        toast({
          title: 'Member Registration',
          status: 'error',
          description:
            'A Member with the provided phone number already exists.',
          isClosable: false,
          duration: 2500,
        });
      } else if (error.data?.message === 'Duplicate Email') {
        setShowTerms(false);
        toast({
          title: 'Member Registration',
          status: 'error',
          description: 'A Member with the provided email already exists.',
          isClosable: false,
          duration: 2500,
        });
      } else {
        toast({
          title: 'Member Registration',
          status: 'error',
          description:
            'An Error Occurred While sending the request. Please try again after some time.',
          isClosable: false,
          duration: 1500,
        });
      }
    }
  };

  const handleBackButtonClick = () => {
    // If on form page, navigate to home page
    if (!showTerms) {
      navigate('/'); // Navigate to home page
    } else {
      // Navigate back to the previous step
      setShowTerms(false);
    }
  };

  return (
    <div className="w-screen h-screen bg-[#F0F0F0] flex flex-row">
      <form
        onSubmit={onHandleSubmit}
        className="flex flex-row w-full xl:w-1/2 mx-auto"
      >
        <div className="w-full px-16 md:px-24 xl:px-36 py-8 md:py-16 space-y-2">
          <div className="flex items-center justify-center">
            <a href="/">
              <img src="/navlogo.png" alt="logo" className="w-32 h-auto " />
            </a>
          </div>

          {!showTerms ? (
            <div className="space-y-6">
              <p className="font-bold text-2xl flex text-black items-center justify-center mb-4">
                Become a Member
              </p>
              <FormControl id="name" mb={4} isRequired>
                <FormLabel className="text-black">Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey', color: 'black' }}
                />
              </FormControl>

              <FormControl id="email" mb={4} isRequired>
                <FormLabel className="text-black">Email Address</FormLabel>
                <Input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey', color: 'black' }}
                />
              </FormControl>

              <FormControl id="phoneNumber" mb={4} isRequired>
                <FormLabel className="text-black">Contact Number</FormLabel>
                <PhoneInput
                  country={'in'}
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  inputProps={{
                    required: true,
                    style: {
                      width: '100%',
                      border: '0.5px solid grey',
                      backgroundColor: 'transparent',
                      color: 'black',
                    },
                  }}
                  name="phoneNumber"
                />
              </FormControl>

              <div className="flex flex-col xl:flex-row space-x-0 xl:space-x-4">
                <FormControl id="country" mb={4} isRequired>
                  <FormLabel className="text-black">Country</FormLabel>
                  <Input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    style={{ border: '0.5px solid grey', color: 'black' }}
                  />
                </FormControl>

                {formData.country.toLowerCase() === 'bhutan' && ( // Render Dzongkhag select field if country is Bhutan
                  <FormControl id="dzongkhag" mb={4} isRequired>
                    <FormLabel className="text-black">Dzongkhag</FormLabel>
                    <Select
                      placeholder="Select Dzongkhag"
                      name="dzongkhag"
                      value={formData.dzongkhag}
                      onChange={handleChange}
                      style={{ border: '0.5px solid grey', color: 'black' }}
                    >
                      <option value="Bumthang">Bumthang</option>
                      <option value="Chhukha">Chhukha</option>
                      <option value="Dagana">Dagana</option>
                      <option value="Gasa">Gasa</option>
                      <option value="Haa">Haa</option>
                      <option value="Lhuntse">Lhuntse</option>
                      <option value="Mongar">Mongar</option>
                      <option value="Paro">Paro</option>
                      <option value="Pema Gatshel">Pema Gatshel</option>
                      <option value="Punakha">Punakha</option>
                      <option value="Samdrup Jongkhar">Samdrup Jongkhar</option>
                      <option value="Samtse">Samtse</option>
                      <option value="Sarpang">Sarpang</option>
                      <option value="Thimphu">Thimphu</option>
                      <option value="Trashigang">Trashigang</option>
                      <option value="Trashi Yangtse">Trashi Yangtse</option>
                      <option value="Trongsa">Trongsa</option>
                      <option value="Tsirang">Tsirang</option>
                      <option value="Wangdue Phodrang">Wangdue Phodrang</option>
                      <option value="Zhemgang">Zhemgang</option>
                    </Select>
                  </FormControl>
                )}
              </div>
              <div className="w-1/3 ml-auto mr-0">
                <Button
                  type="button"
                  colorScheme="yellow"
                  bgColor="#FDBC06"
                  onClick={onNextButtonClick}
                  className="w-full"
                >
                  Next
                </Button>
              </div>
            </div>
          ) : (
            <div className="space-y-4 text-black">
              <p className="font-bold text-xl">Members Terms</p>
              <ol className="text-sm">
                <li className="flex flex-row space-x-2">
                  <p>1.</p>
                  <p>
                    Anyone can be a part of the group if they agree with the
                    terms and conditions of this policy.
                  </p>
                </li>
                <li className="flex flex-row space-x-2">
                  <p>2.</p>
                  <p>
                    Members must exhibit the values of the group as they become
                    prime ambassadors of this movement.
                  </p>
                </li>
                <li className="flex flex-row space-x-2">
                  <p>3.</p>
                  <p>
                    If someone is thinking of exiting the group, that individual
                    may do so by submitting a letter to the office bearers and
                    stating the reason if possible for group assessment.
                  </p>
                </li>
                <li className="flex flex-row space-x-2">
                  <p>4.</p>
                  <p>
                    If someone has been inactive for 3 months without any
                    monthly donations, that individual will be termed a dormant
                    individual and will be removed from the group by default.
                  </p>
                </li>
              </ol>
              <p className="text-sm">
                The policy had been adopted on 29th of January, 2023 as voted by
                more than half the members of the group through online telegram.
              </p>
              <p className="text-sm">
                All the members agree to the terms and conditions as stated and
                shared above.Necessary amendments will be made according to the
                issues that arise.
              </p>
              <FormControl
                display="flex"
                alignItems="center"
                mb="40px"
                className="space-x-4"
              >
                <Checkbox
                  name="termsAndCondition"
                  size="lg"
                  colorScheme="yellow"
                  isChecked={isChecked}
                  onChange={handleCheckboxChange}
                  style={{ borderColor: 'black' }}
                />
                <p mb="0" ml="10px" className="text-sm">
                  Becoming a member means you’re okay with our{' '}
                  <span className="text-[#FDBC06]">Terms of Service</span> and
                  our <span className="text-[#FDBC06]">Privacy Policy</span>.
                </p>
              </FormControl>
              <div className="w-1/3 ml-auto mr-0">
                <Button
                  type="submit"
                  colorScheme="yellow"
                  bgColor="#FDBC06"
                  onClick={onHandleSubmit}
                  isLoading={isLoading}
                  className="w-full"
                >
                  SIGN UP
                </Button>
              </div>
            </div>
          )}
        </div>
      </form>
      <div
        className="absolute top-0 left-0 p-8 cursor-pointer text-black"
        onClick={handleBackButtonClick}
      >
        <HiArrowLeft className="w-8 h-8" />
      </div>
      <div className="lg:flex hidden md:w-[45%] h-screen flex-col justify-center items-center">
        <img
          src="/register.jpg"
          alt="register"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export default Member;
