import React from "react";

const   NotFound = () => {
  return (
    <div className="h-screen w-full">
      <div className='bg-[#0F1320] min-h-[100px] min-w-screen'></div>
      <div className="flex h-full justify-center items-center">
        <h1 className="text-4xl font-bold">404 - Page Not Found</h1>
      </div>
    </div>
  );
};

export default NotFound;
