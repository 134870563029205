import Slider from './Slider';
import Slider2 from './Slider2';
import Landing from '../features/home/Landing';
import AboutUs from './AboutUs';
import { motion } from "framer-motion";


const Public = () => {
  const textEffect = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: { duration: 1.0, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };
  return (
    <>
      <div className="relative">
        <Landing />
      </div>
      <div className="min-h-screen bg-[#F0F0F0]">
        <div>
          <AboutUs />
        </div>

        <motion.div 
        className='bg-[#ffff] pt-16 shadow-lg'>
          <motion.h1 
          variants={textEffect}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true }}
          className="font-bold text-[#484848] text-center text-[40px] md:text-[50px] ">
            Projects
          </motion.h1>
          <motion.p 
          variants={textEffect}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true }}
          className='flex justify-center text-sm text-center xl:text-start font-light text-[#484848] py-8 px-4 xl:px-0'>Discover our impactful social initiatives, designed to create positive change and empower communities.</motion.p>
          <Slider />
        </motion.div>

        <motion.div className='bg-[#ffff] pt-4 my-16 shadow-lg'>
          <motion.h1 
          variants={textEffect}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true }}
          className="font-bold text-[#484848] text-center text-[40px] md:text-[50px] mt-16">
            Announcements
          </motion.h1>
          <motion.p 
          variants={textEffect}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true }}
          className='flex justify-center text-sm text-center xl:text-start font-light text-[#484848] py-8'>Stay updated with our latest announcements for exciting news, events, and opportunities!</motion.p>
          <Slider2 />
        </motion.div>
      </div>
    </>
  );
};

export default Public;
