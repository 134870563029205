import { Outlet, Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useState } from 'react';

import { UseSelector, useSelector } from 'react-redux';
import { useRefreshMutation } from './authApiSlice';
import { Spinner } from '@chakra-ui/react';

const PersistLogin = () => {
  const effectRan = useRef(false);
  const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] =
    useRefreshMutation();
  const token = useSelector(state => state.auth.token);
  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (effectRan.current === true || process.env.NODE_ENV !== 'development') {
      console.log(
        'verifying refresh token',
        effectRan.current,
        process.env.NODE_ENV
      );
      const verifyRefreshToken = async () => {
        console.log('verifying refresh token inside');
        try {
          await refresh();
          setRefreshSuccess(true);
        } catch (error) {
          console.error(error);
        }
      };

      if (!token) verifyRefreshToken();
    }
    console.log(
      'verifying refresh token outside',
      effectRan.current,
      process.env.NODE_ENV
    );
    return () => (effectRan.current = true);
  }, []);

  let content;
  if (isLoading) {
    content = (
      <div className="h-screen w-screen flex justify-center items-center">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </div>
    );
  } else if (isError) {
    content = (
      <div className="h-screen w-screen flex justify-center items-center">
        {`${error.data?.message}-`}
        <Link to="/login" className="text-cyan-500">
          Please Login again
        </Link>
      </div>
    );
  } else if (isSuccess && refreshSuccess) {
    content = <Outlet />;
  } else if (token && isUninitialized) {
    content = <Outlet />;
  }

  return content;
};

export default PersistLogin;
