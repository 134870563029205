import { Outlet } from 'react-router-dom';
import DashHeader from './DashHeader';
import DashSidebar, { DashSidebarItem } from './DashSidebar';

import { RiDashboardLine } from 'react-icons/ri';
import { BsFillPeopleFill } from 'react-icons/bs';
import { FaMoneyBill } from 'react-icons/fa';
import { MdLocationSearching } from 'react-icons/md';
import { FaProjectDiagram } from 'react-icons/fa';
import { MdAnnouncement } from 'react-icons/md';

const DashLayout = () => {
  return (
    <div className="flex flex-row overflow-x-auto">
      <div className="basis-1/5">
        <DashSidebar>
          <DashSidebarItem
            icon={<RiDashboardLine />}
            text={'Dashboard'}
            path={'/dash'}
          />
          <DashSidebarItem
            icon={<BsFillPeopleFill />}
            text={'Member'}
            path={'/dash/member'}
          />
          <DashSidebarItem
            icon={<FaMoneyBill />}
            text={'Donation'}
            path={'/dash/donation'}
          />
          <DashSidebarItem
            icon={<MdLocationSearching />}
            text={'Opportunity'}
            path={'/dash/opportunity'}
          />
          <DashSidebarItem
            icon={<FaProjectDiagram />}
            text={'Project'}
            path={'/dash/project'}
          />
          <DashSidebarItem
            icon={<MdAnnouncement />}
            text={'Announcement'}
            path={'/dash/announcement'}
          />
        </DashSidebar>
      </div>
      <div className="basis-4/5 bg-[#FAFBFF]">
        <Outlet />
      </div>
    </div>
  );
};

export default DashLayout;
