import { background, color } from '@chakra-ui/react';
import ReactQuill from 'react-quill';

export default function Editor({ value, onChange }) {
  const modules = {
    toolbar: [
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
    ],
  };
  const formats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'align',
  ];
  return (
    <div className="content">
      <ReactQuill
        value={value}
        theme={'snow'}
        formats={formats}
        onChange={onChange}
        modules={modules}
      />
    </div>
  );
}
