
import React, { useState } from 'react';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';

// ChakraUi
import { Spinner } from '@chakra-ui/react';

// API
import { useGetAllOpportunitiesQuery } from '../features/opportunity/OpportunityApiSlice';

const OpportunityPage = () => {
  const {
    data: opportunityList,
    error,
    isLoading,
  } = useGetAllOpportunitiesQuery({
    pollingInterval: 5000,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const totalPages = Math.ceil(
    opportunityList?.opportunities.length / itemsPerPage
  );
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOpportunities = opportunityList?.opportunities.slice(
    startIndex,
    endIndex
  );

  const goToPage = page => {
    setCurrentPage(Math.max(1, Math.min(page, totalPages)));
  };

  if (error) {
    return (
      <div className="flex justify-center font-bold text-red-400">
        <p>An Error Occurred....</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Spinner size="xl" />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center pb-16">
      {currentOpportunities?.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 justify-items-center px-4">
          {currentOpportunities.map((opportunity, index) => (
            <div
              key={index}
              className="w-full max-w-md rounded-xl shadow-lg overflow-hidden bg-white"
            >
              <img
                src={
                  opportunity.image?.url ||
                  'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg'
                }
                alt={opportunity.title}
                className="w-full h-48 object-contain"
              />
              <div className="p-6 space-y-4 text-center">
                <h1 className="font-bold text-2xl">{opportunity.title}</h1>
                <p className="text-[#8A8A8A]">{"posted " + formatDistanceToNow(new Date(opportunity.date), { addSuffix: true })}</p>
                <button className="py-2 px-4 bg-black text-white mt-4">
                  <Link
                    to={`/opportunity/opportunityDetail/${index + startIndex}`}
                    className='cursor-pointer'
                    state={opportunity}
                  >
                    Read More
                  </Link>
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex justify-center font-bold">
          <p>No Listings as of now</p>
        </div>
      )}

      {currentOpportunities?.length > 0 ? (
        <div className="flex justify-center mt-8">
          <button
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-3 py-1 mr-2"
          >
            <GoChevronLeft />
          </button>
          <p className="text-sm">
            Page {currentPage} of {totalPages}
          </p>
          <button
            onClick={() => goToPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-3 py-1 ml-2"
          >
            <GoChevronRight />
          </button>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default OpportunityPage;
