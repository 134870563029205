import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router';
import { Spinner } from '@chakra-ui/react';

// API endpoint
import { useStatisticsQuery } from './homeApiSlice';

const slideUp = {
  initial: {
    y: 300,
    opacity: 0,
  },
  enter: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.6, ease: [0.33, 1, 0.68, 1], delay: 3.4 },
  },
};

const stagger = {
  initial: {},
  enter: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.4,
    },
  },
};

const fadeInUp = {
  initial: {
    y: 20,
    opacity: 0,
  },
  enter: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.6, ease: [0.33, 1, 0.68, 1], delay: 0.5 },
  },
};

export default function Landing() {
  const {
    data: statistics,
    error,
    isLoading,
  } = useStatisticsQuery(
    {},
    {
      pollingInterval: 5000,
    }
  );
  const navigate = useNavigate();

  const [statisticsIndex, setStatisticsIndex] = useState(0);
  const statisticsData = [
    {
      title: 'Monthly Contributions',
      value: statistics?.monthlyDonationAmount,
    },
    { title: 'Active Members', value: statistics?.memberCount },
    { title: 'Project Completed', value: 21 },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setStatisticsIndex(prevIndex => (prevIndex + 1) % statisticsData.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);
  return (
    <motion.main
      initial="initial"
      animate="enter"
      className={'relative flex h-screen overflow-hidden w-full'}
    >
      <img
        src={'/hero.png'}
        width={2200}
        height={1000}
        alt="bhutan_mon"
        className="w-full h-full absolute object-cover object-[40%] md:object-center"
        style={{
          left: '0',
          right: '0',
          margin: 'auto',
        }}
      />
      <div className="w-full h-full absolute z-0" />
      <motion.div
        variants={stagger}
        initial="initial"
        animate="enter"
        className="flex flex-col text-center xr:text-start gap-8 xl:w-[45%] text-white absolute px-8 xl:px-16 md:py-16 xl:py-6 right-0 top-[15%]"
      >
        <motion.div variants={fadeInUp}>
          <motion.h1
            variants={fadeInUp}
            className="text-3xl md:text-5xl font-bold"
          >
            Love your country intelligently
          </motion.h1>
        </motion.div>
        <motion.div variants={fadeInUp} className="text-[#DDE2FF]">
          <motion.h2
            variants={fadeInUp}
            className="text-sm md:text-[18px] xl:text-[16px] inline"
          >
            “We are yesterday's children, today we are tomorrow’s parents,
            responsible for our nation and for laying the foundations for our
            next generations. Remember, the future will reflect what we do
            today. We cannot foresee it but as long as we approach it with the
            right intentions and values, I assure you of a very bright future –
            for yourself and your children and for our special nation.”
          </motion.h2>
          <p>- His Majesty Druk Gyalpo Jigme Khesar Namgyel Wangchuck</p>
        </motion.div>
      </motion.div>

      <motion.div
        variants={fadeInUp}
        className="absolute bottom-[5%] left-[5%] lg:left-[15%] xl:left-[20%] transform -translate-x-1/2 hidden lg:flex lg:flex-row"
      >
        <div className="w-[700px] h-[150px] backdrop-blur-lg backdrop-filter bg-opacity-[10%] bg-[#0F1320] rounded-tl-lg rounded-bl-lg p-4">
          <div className="grid grid-cols-3 text-white">
            <CountUpSection
              title="Monthly Contributions"
              value={statistics?.monthlyDonationAmount}
              isLoading={isLoading}
            />
            <CountUpSection
              title="Active Members"
              value={statistics?.memberCount}
              isLoading={isLoading}
            />
            <CountUpSection title="Project Completed" value={statistics?.projectCount} isLoading={isLoading} />
          </div>
        </div>
        <div className="bg-[#FDBC06] w-[200px] h-[150px] rounded-tr-lg rounded-br-lg shadow-lg">
          <div
            className="p-6 mt-2 cursor-pointer"
            onClick={() => {
              navigate('/member/registration');
            }}
          >
            <p className="text-[18px] font-bold">Our goal: A happier Bhutan.</p>
            <p className="border-b-2 border-[#000000] w-1/4 items-start mb-2"></p>
            <button className="text-[12px] font-light flex items-center">
              Become a volunteer
              <img src="/arrow.svg" alt="arrow" className="pl-2" />
            </button>
          </div>
        </div>
      </motion.div>

      <motion.div
        variants={fadeInUp}
        className="absolute lg:hidden  inset-0 mt-[500px] flex items-center justify-center"
      >
        <div className="flex flex-row">
          <div className="w-full h-[110px] text-white  backdrop-blur-lg backdrop-filter bg-opacity-[10%] bg-[#0F1320] rounded-tl-md rounded-bl-md p-2">
            <CountUpSection
              title={statisticsData[statisticsIndex].title}
              value={statisticsData[statisticsIndex].value}
            />
          </div>
          <div className="bg-[#FDBC06] w-full h-[110px] rounded-tr-md rounded-br-md shadow-lg">
            <div
              className="p-2 mt-2 cursor-pointer"
              onClick={() => {
                navigate('/member/registration');
              }}
            >
              <p className="text-md font-bold">Our goal: A happier Bhutan.</p>
              <p className="border-b-2 border-[#000000] w-1/4 items-start mb-2"></p>
              <button className="text-[12px] font-light flex items-center">
                Become a volunteer
                <img src="/arrow.svg" alt="arrow" className="pl-2" />
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.main>
  );
}

const CountUpSection = ({ title, value, isLoading }) => {
  return (
    <div className="text-center mt-4">
      {isLoading ? (
        <Spinner />
      ) : (
        <CountUp
          end={value}
          duration={3}
          separator=","
          className="text-lg xl:text-[35px] font-bold  pb-2 "
        />
      )}
      <p className="border-b-2 border-white w-1/5 mx-auto "></p>
      <p className="text-[12px] xl:text-[14px] font-light mt-2">{title}</p>
    </div>
  );
};
