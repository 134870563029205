import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Layouts
import Layout from './components/Layout';
import DashLayout from './components/DashLayout';

// Auth
import PersistLogin from './features/auth/PersistLogin';

// Pages
import Dashboard from './features/dashboard/Dashboard.jsx';
import Home from './components/Home';
import Login from './features/auth/Login';
import NotFound from './components/NotFound';
import Member from './features/member/Member';
import MemberPage from './features/member/MemberPage';
import Project from './features/project/Project.jsx';
import Contact from './features/contact/Contact.jsx';
import Opportunity from './features/opportunity/Opportunity';
import OpportunityDetail from './features/opportunity/opportunityDetail/OpportunityDetail.jsx'
import Announcement from './features/announcement/Announcement.jsx';
import AnnouncementDetail from './features/announcement/announcementDetail/announcementDetails.jsx';
import ProjectDetail from './features/project/projectDetail/ProjectDetail.jsx';
import DashboardDonationPage from './pages/DashboardDonationPage.jsx';
import DashboardMemberPage from './pages/DashboardMemberPage.jsx';
import DashboardProjectPage from './pages/DashboardProjectPage.jsx';
import DashboardOpportunityPage from './pages/DashboardOpportunityPage.jsx';
import DashboardAnnouncement from './pages/DashboardAnnouncement.jsx';
import Preview from './pages/Preview.jsx';


function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Semthuen Zhidhey Zhabtok</title>
        <meta name="description" content="A Social Movement for a Happier and Rich Bhutan." />
      </Helmet>
      <Routes>
        {/* Website */}
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="member" element={<MemberPage />} />
          <Route path="project" element={<Project />} />
          <Route path="contact" element={<Contact />} />
          <Route path="opportunity" element={<Opportunity />} />
          <Route 
            path="opportunity/opportunityDetail/:id" 
            element={<OpportunityDetail />} 
          />
          <Route path="announcement" element={<Announcement />} />
          <Route
            path="announcement/announcementDetail/:announcementId"
            element={<AnnouncementDetail />}
          />
          <Route
            path="project/projectDetail/:projectId"
            element={<ProjectDetail />}
          />
          <Route path="*" element={<NotFound />} /> {/* 404 Page */}
        </Route>
        <Route path="member/registration" element={<Member />} />
        {/* Dashboard */}
        <Route element={<PersistLogin />}>
          <Route path="dash" element={<DashLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="member" element={<DashboardMemberPage />} />
            <Route path="donation" element={<DashboardDonationPage />} />
            <Route path="project" element={<DashboardProjectPage />} />
            <Route path="opportunity" element={<DashboardOpportunityPage />} />
            <Route path="announcement" element={<DashboardAnnouncement />} />
          </Route>
        </Route>
        <Route path="preview/:type" element={<Preview />} />
      </Routes>
    </>
  );
}

export default App;
