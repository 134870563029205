import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import { useGetAllProjectQuery } from '../features/home/homeApiSlice';
import { Spinner } from '@chakra-ui/react';

const Slider2 = () => {
  const [slidesToShow, setSlidesToShow] = useState(3); // Default to 3 slides
  const [latestAnnouncement, setLatestAnnouncement] = useState([]);
  const { data: announcements, isLoading } = useGetAllProjectQuery({
    type: 'announcement',
  });

  useEffect(() => {
    if (announcements) {
      const announcementCount = announcements?.projects.length;
      const newArray =
        announcementCount <= 3
          ? announcements?.projects.slice(0, announcementCount)
          : announcements?.projects.slice(0, 3);
      setLatestAnnouncement(newArray);
    }
  }, [announcements]);
  useEffect(() => {
    const handleResize = () => {
      // Adjust slidesToShow based on window width
      if (window.innerWidth < 768) {
        setSlidesToShow(1); // Show one slide for phone version
      } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        setSlidesToShow(2); // Show two slides for tablet version
      } else {
        setSlidesToShow(3); // Show three slides for larger screens
      }
    };

    // Attach resize event listener
    window.addEventListener('resize', handleResize);

    // Initial call to set slidesToShow based on initial window width
    handleResize();

    // Cleanup: remove resize event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const settings = {
    dots: true,
    infinite: announcements?.projects.length > 2,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    appendDots: dots => (
      <div style={{ bottom: '-50px' }}>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          width: '20px',
          height: '5px',
          background: '#888888',
          borderRadius: '5px',
        }}
      ></div>
    ),
  };

  return (
    <>
      <div className="px-4 xl:px-16 pt-8 pb-28 bg-[#ffff]">
        {isLoading ? (
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Spinner
              size={'xl'}
              thickness="4px"
              emptyColor="gray.200"
              color="blue.500"
            />
          </div>
        ) : announcements?.projects?.length === 0 ? (
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              fontSize: '50px',
            }}
          >
            No Announcements
          </div>
        ) : (
          <Slider {...settings}>
            {latestAnnouncement?.map((announcement, index) => {
              return (
                <div className="px-8" key={index}>
                  <Link
                    to={`/announcement/announcementDetail/${index}`}
                    state={announcements}
                  >
                    <div className="border rounded-lg shadow-md overflow-hidden">
                      <img
                        src={
                          announcement?.images[0]?.url ||
                          'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg'
                        }
                        alt="1"
                        className="w-full h-[400px] object-cover"
                      />
                      <p className="px-4 pt-4 text-lg font-bold">
                        {announcement?.projectName}
                      </p>
                      <p className="p-4 text-md text-[#8A8A8A]">
                        {new Date(
                          announcement?.date.split('T')[0]
                        ).toLocaleString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                      </p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </Slider>
        )}
      </div>
    </>
  );
};

export default Slider2;
