import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { HiDotsVertical } from 'react-icons/hi';
import { Spinner } from '@chakra-ui/react';
import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import PhoneInput from 'react-phone-input-2';
import {
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Select,
} from '@chakra-ui/react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
} from '@chakra-ui/react';

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

import { useGetAllPaginatedMembersQuery } from '../member/memberApiSlice';
import {
  useDeleteMemberMutation,
  useUpdateMemberMutation,
} from './dashApiSlice';

import Pagination from './Pagination';

let initialState = {
  name: '',
  email: '',
  phoneNumber: '',
  country: '',
  dzongkhag: '',
};

const Members = () => {
  const toast = useToast();

  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  // Drawer
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Alert
  const {
    isOpen: isAlertOpen,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();
  const cancelRef = React.useRef();

  // Model
  const {
    isOpen: isModelOpen,
    onOpen: onModelOpen,
    onClose: onModelClose,
  } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [selectedMember, setSelectedMember] = useState(initialState);
  const [formData, setFormData] = useState(initialState);

  // API
  const {
    data: members,
    error,
    isLoading,
  } = useGetAllPaginatedMembersQuery(
    { limit, page: currentPage },
    { pollingInterval: 5000 }
  );
  const [deleteMember, { isLoading: isDeleting, error: deleteError }] =
    useDeleteMemberMutation();
  const [updateMember, { isLoading: isUpdating, error: updateError }] =
    useUpdateMemberMutation();

  if (members?.members.result.length === 0 && !isLoading) {
    if (members?.members.pageCount) {
      if (
        members?.members.previous.page === 0 ||
        members?.members.previous.page === null ||
        members?.members.previous.page === undefined
      ) {
        setCurrentPage(members?.members.previous.page);
      } else {
        setCurrentPage(1);
      }
    }
  }

  const onHandleDelete = async () => {
    if (selectedMember) {
      try {
        await deleteMember({ id: selectedMember._id }).unwrap();
        toast({
          title: 'Membership',
          description: 'Member deleted successfully',
          status: 'success',
          duration: 8000,
          isClosable: true,
        });
        onClose();
        onCloseAlert();
      } catch (error) {
        toast({
          title: 'Membership',
          description: 'The Operation has failed, please try again later.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
        onCloseAlert();
      }
    }
  };

  const handleChange = e => {
    if (e.target) {
      setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    } else {
      setFormData(prev => ({ ...prev, phoneNumber: e }));
    }
  };

  const onHandleSubmit = async e => {
    e.preventDefault();
    const { name, email, phoneNumber, country, dzongkhag } = selectedMember;

    // Form Validation
    if (name && email && phoneNumber && country) {
      if (!validateEmail(email)) {
        return toast({
          title: 'Form Validation',
          status: 'error',
          description: 'Please enter a valid email address.',
          isClosable: false,
          duration: 2500,
        });
      }

      if (country.toLowerCase() === 'bhutan' && dzongkhag.trim() === '') {
        return toast({
          title: 'Form Validation',
          status: 'error',
          description: 'Please enter a dzongkhag, since you are from bhutan.',
          isClosable: false,
          duration: 2500,
        });
      }
    } else {
      return toast({
        title: 'Form Validation',
        status: 'error',
        description: 'Please fill in all the required fields.',
        isClosable: false,
        duration: 2500,
      });
    }

    try {
      await updateMember({ ...formData }).unwrap();

      onModelClose();
      setSelectedMember(formData);

      setFormData(prev => ({ ...prev, ...formData }));

      toast({
        title: 'Member Edition',
        description: 'Successfully edited member details.',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
    } catch (error) {
      toast({
        title: 'Membership',
        description: 'The Operation has failed, please try again later.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const validateEmail = email => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  // Table
  // if (isLoading) {
  //   return (
  //     <div className="flex justify-center items-center h-screen">
  //       <Spinner />
  //     </div>
  //   );
  // }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        Error while loading the table.
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="w-4/5 mt-10 p-5 border rounded-md">
        <div className="flex justify-between mb-5 items-center border-b-2 p-5">
          <h1 className="font-medium text-lg">All Members</h1>
        </div>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>PhoneNumber</Th>
                <Th>dzongkhag</Th>
                <Th>country</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr style={{ height: '50px' }}>
                  <Td colSpan={6} style={{ textAlign: 'center' }}>
                    <Spinner />
                  </Td>
                </Tr>
              ) : members?.members.result.length === 0 ? (
                <Tr style={{ height: '50px' }}>
                  <Td colSpan={6} style={{ textAlign: 'center' }}>
                    No Content
                  </Td>
                </Tr>
              ) : (
                members?.members.result.map(member => {
                  return (
                    <Tr key={member._id}>
                      <Td>
                        {member.name.length > 10
                          ? member.name.slice(0, 10) + '...'
                          : member.name}
                      </Td>
                      <Td>
                        {member.email.length > 10
                          ? member.email.slice(0, 10) + '...'
                          : member.email}
                      </Td>
                      <Td>{member.phoneNumber}</Td>
                      <Td>{member.dzongkhag || 'Nil'}</Td>
                      <Td>{member.country}</Td>
                      <Td>
                        <Button
                          onClick={() => {
                            setSelectedMember(member);
                            onOpen();
                            setFormData(member);
                          }}
                        >
                          <HiDotsVertical />
                        </Button>
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </TableContainer>

        {members?.members.result.length !== 0 && !isLoading && (
          <div class={'flex justify-end'}>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageCount={members?.members.pageCount}
            />
          </div>
        )}
      </div>

      <Drawer isOpen={isOpen} onClose={onClose} size={'sm'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="2px" className="text-[#585858]">Member Details</DrawerHeader>

          <DrawerBody>
            {selectedMember && (
              <div>
                <div className="mb-5">
                  <h1 className="text-[#585858] font-semibold mb-1">Name</h1>
                  <p className="font-semibold">{selectedMember.name}</p>
                </div>
                <div className="mb-5">
                  <h1 className="text-[#585858] font-semibold mb-1">Email</h1>
                  <p className="font-semibold">{selectedMember.email}</p>
                </div>
                <div className="mb-5">
                  <h1 className="text-[#585858] font-semibold mb-1">
                    Phone Number
                  </h1>
                  <p className="font-semibold">{selectedMember.phoneNumber}</p>
                </div>
                <div className="mb-5">
                  <h1 className="text-[#585858] font-semibold mb-1">Address</h1>
                  <p className="font-semibold">
                    {selectedMember.dzongkhag} {selectedMember.dzongkhag && ','}{' '}
                    {selectedMember.country}
                  </p>
                </div>
              </div>
            )}
          </DrawerBody>

          <DrawerFooter
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button variant="outline" mr={3} onClick={onModelOpen}>
              Edit
            </Button>
            <Button colorScheme="blue" onClick={onOpenAlert}>
              Delete
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Member
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Cancel
              </Button>
              <Button
                colorScheme={'red'}
                onClick={onHandleDelete}
                ml={3}
                isLoading={isDeleting}
              >
                delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isModelOpen}
        onClose={onModelClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Members</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form>
              <FormControl id="name" mb={4} isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey' }}
                  isDisabled={isUpdating}
                />
              </FormControl>

              <FormControl id="email" mb={4} isRequired>
                <FormLabel>Email Address</FormLabel>
                <Input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey' }}
                  isDisabled={isUpdating}
                />
              </FormControl>

              <FormControl id="phoneNumber" mb={4} isRequired>
                <FormLabel>Contact Number</FormLabel>
                <PhoneInput
                  country={'in'}
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  inputProps={{
                    required: true,
                    style: {
                      width: '100%',
                      border: '0.5px solid grey',
                      backgroundColor: 'transparent',
                    },
                  }}
                  name="phoneNumber"
                />
              </FormControl>

              <FormControl id="country" mb={4} isRequired>
                <FormLabel>Country</FormLabel>
                <Input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey' }}
                  isDisabled={isUpdating}
                />
              </FormControl>

              {formData.country.toLowerCase() === 'bhutan' && (
                <FormControl id="dzongkhag" mb={4}>
                  <FormLabel>Dzongkhag</FormLabel>
                  <Select
                    placeholder="Select Dzongkhag"
                    name="dzongkhag"
                    value={formData.dzongkhag}
                    onChange={handleChange}
                    style={{ border: '0.5px solid grey' }}
                    isDisabled={isUpdating}
                  >
                    <option value="Bumthang">Bumthang</option>
                    <option value="Chhukha">Chhukha</option>
                    <option value="Dagana">Dagana</option>
                    <option value="Gasa">Gasa</option>
                    <option value="Haa">Haa</option>
                    <option value="Lhuntse">Lhuntse</option>
                    <option value="Mongar">Mongar</option>
                    <option value="Paro">Paro</option>
                    <option value="Pema Gatshel">Pema Gatshel</option>
                    <option value="Punakha">Punakha</option>
                    <option value="Samdrup Jongkhar">Samdrup Jongkhar</option>
                    <option value="Samtse">Samtse</option>
                    <option value="Sarpang">Sarpang</option>
                    <option value="Thimphu">Thimphu</option>
                    <option value="Trashigang">Trashigang</option>
                    <option value="Trashi Yangtse">Trashi Yangtse</option>
                    <option value="Trongsa">Trongsa</option>
                    <option value="Tsirang">Tsirang</option>
                    <option value="Wangdue Phodrang">Wangdue Phodrang</option>
                    <option value="Zhemgang">Zhemgang</option>
                  </Select>
                </FormControl>
              )}

              <Button mr={3} onClick={onModelClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="yellow"
                bgColor="#FDBC06"
                onClick={onHandleSubmit}
                isLoading={isUpdating}
              >
                Save
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Members;
