import { apiSlice } from '../../app/api/apiSlice';

export const homeApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    memberRegistrationRequestList: builder.query({
      query: payload => ({
        url: `/admin/member/pending?page=${payload.page}&limit=${payload.limit}`,
        method: 'GET',
      }),
      providesTags: ['pendingMembersList'],
    }),
    memberRegistrationStatusChanger: builder.mutation({
      query: payload => ({
        url: `/admin/member/${payload.id}`,
        method: 'PATCH',
        body: { ...payload.data },
      }),
      invalidatesTags: ['pendingMembersList', 'statistic'],
    }),
    deleteMember: builder.mutation({
      query: payload => ({
        url: `/admin/member/${payload.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['pendingMembersList', 'statistic'],
    }),
    updateMember: builder.mutation({
      query: payload => ({
        url: `/admin/member/update/${payload._id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['pendingMembersList', 'statistic'],
    }),
    getDonations: builder.query({
      query: payload => ({
        url: `/donation?page=${payload.page}&limit=${payload.limit}`,
        method: 'GET',
      }),
      providesTags: ['donation'],
    }),
    deleteDonation: builder.mutation({
      query: payload => ({
        url: `/donation/${payload.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['donation'],
    }),
    addDonation: builder.mutation({
      query: payload => ({
        url: '/donation',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['donation'],
    }),
    updateDonation: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/donation/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['donation'],
    }),
    dashboardStatistic: builder.query({
      query: () => '/admin',
    }),
    addProject: builder.mutation({
      query: payload => ({
        url: '/project',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['project'],
    }),
    deleteProject: builder.mutation({
      query: payload => ({
        url: `/project/${payload.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['project'],
    }),
    updateProject: builder.mutation({
      query: payload => ({
        url: `/project`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['project'],
    }),
  }),
});

export const {
  useMemberRegistrationRequestListQuery,
  useMemberRegistrationStatusChangerMutation,
  useDeleteMemberMutation,
  useUpdateMemberMutation,
  useGetDonationsQuery,
  useDeleteDonationMutation,
  useAddDonationMutation,
  useUpdateDonationMutation,
  useDashboardStatisticQuery,
  useAddProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
} = homeApiSlice;
