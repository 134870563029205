import Announcement from '../features/dashboard/Announcement';

const DashboardAnnouncement = () => {
  return (
    <div className="h-full">
      <Announcement />
    </div>
  );
};

export default DashboardAnnouncement;
