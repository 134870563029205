import React, { useEffect, useState } from 'react';
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import { AiFillFileImage } from 'react-icons/ai';

export const PostImageUploaders = ({
  handleImageChange,
  preview,
  setPreview,
}) => {
  const [images, setImage] = useState([]);
  const [files, setFiles] = useState([]);
  const [publicIds, setPublicIds] = useState([]);
  const [deleteIds, setDeleteIds] = useState([]);
  useEffect(() => {
    const url = [];
    const ids = [];
    preview?.forEach(prev => {
      url.push(prev.url);
      ids.push(prev.public_id);
    });
    setPublicIds(ids);
    setImage(url);
  }, []);
  const handleChange = files => {
    // const names = [];
    const url = Array.from(files).map(file => URL.createObjectURL(file));
    // Array.from(files).forEach(file => {
    //   names.push(file.name);
    // });
    // setFileNames(names);
    setFiles(Array.from(files));
    setImage(url);
  };
  const newArray = [];
  const handleDelete = index => {
    setImage([...images.slice(0, index), ...images.slice(index + 1)]);
    setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
    setPreview([...preview.slice(0, index), ...preview.slice(index + 1)]);

    if (publicIds.length > 0) {
      newArray.push(publicIds[index]);
    }
    setPublicIds([...publicIds.slice(0, index), ...publicIds.slice(index + 1)]);
    setDeleteIds([...deleteIds, newArray]);
  };
  useEffect(() => {
    handleImageChange(files, deleteIds);
  }, [files]);
  return (
    <div className="my-5 flex justify-between items-center flex-col">
      <form
        onClick={() => {
          if (images?.length <= 0) {
            document.querySelector('.input-field').click();
          }
        }}
        className="flex flex-col justify-center items-center border border-black border-dashed h-80 w-96 cursor-pointer rounded"
      >
        <input
          type="file"
          accept="image/*"
          className="input-field"
          hidden
          multiple
          onChange={({ target: { files } }) => {
            if (files) {
              handleChange(files);
              handleImageChange(files, deleteIds);
            }
          }}
        />
        {images?.length > 0 ? (
          images.map((image, index) => {
            return (
              <div className="flex justify-between p-2 mt-2 mb-5 rounded bg-[#e9f0ff] w-full">
                <img src={image} width={100} height={100} />
                <MdDelete
                  className="cursor-pointer"
                  onClick={() => {
                    // setFileNames([]);
                    // setImage(null);
                    // handleImageChange('');
                    handleDelete(index);
                  }}
                />
              </div>
            );
          })
        ) : (
          <>
            <MdCloudUpload color="#FDBC06" size={68} />
            <p>Browse Images to upload</p>
          </>
        )}
      </form>

      {/* {fileNames.length > 0
        ? fileNames.map(fileName => {
            return (
              <section className="flex justify-between items-center p-2 mt-2 mb-5 rounded bg-[#e9f0ff] w-full">
                <span className="flex justify-center items-center gap-2">
                  <AiFillFileImage color="#1475cf" />
                  {fileName}
                </span>
                <MdDelete
                  className="cursor-pointer"
                  onClick={() => {
                    setFileNames([]);
                    setImage(null);
                    handleImageChange('');
                  }}
                />
              </section>
            );
          })
        : null} */}
    </div>
  );
};
