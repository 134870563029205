import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { GoChevronLeft } from 'react-icons/go';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const AnnouncementDetails = () => {
  const { announcementId } = useParams();
  const location = useLocation();
  const announcement = location.state.projects[announcementId];
  const [date, setDate] = useState('');
  const formattedDate = announcement.date.split('T')[0];
  const longDate = new Date(formattedDate).toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  useEffect(() => {
    setDate(longDate);
  }, [longDate]);

  if (!announcement) {
    return <p>Announcement not found</p>;
  }

  const textEffect = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: { duration: 1.2, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };

  return (
    <>
      <div className="bg-[#0F1320] min-h-[100px] min-w-screen"></div>
      <div className="bg-[#F0F0F0]">
      <div className='ml-4 lg:ml-8 mt-8 flex flex-row'>
        <GoChevronLeft className='text-yellow-500 mt-1'/>
        <a href='/'>
          <button className='text-yellow-500 font-bold text-md'>Back to Home</button> 
        </a>
      </div>
        <motion.div
          variants={textEffect}
          initial={'offscreen'}
          whileInView={'onscreen'}
          viewport={{ once: true }}
          className="max-w-7xl mx-auto w-screen min-h-screen"
        >
          <h1 className="flex justify-center mt-4 py-6 text-5xl text-black font-extrabold">
            Announcements
          </h1>
          <div className="flex flex-col xl:flex-row xl:space-x-4">
            <hr class="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
            <p className="flex justify-center text-md font-light py-8 text-center lg:px-4">
              A Space to find out what is going on around us. We will keep you
              updated on the latest news.
            </p>
            <hr class="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
          </div>
          <motion.div
            variants={textEffect}
            initial={'offscreen'}
            whileInView={'onscreen'}
            viewport={{ once: true }}
            className="space-y-12 w-full py-8 xl:py-16 mx-auto text-center px-4 xl:px-0"
          >
            <h1 className="font-bold text-4xl">{announcement.projectName}</h1>
            <p className="font-bold text-[#8A8A8A]">{date}</p>
            <motion.p
              variants={textEffect}
              initial={'offscreen'}
              whileInView={'onscreen'}
              viewport={{ once: true }}
              className="text-[#8A8A8A] text-start w-full xl:w-[70%] mx-auto px-4 xl:px-0"
            >
              <div
                className="ql-editor"
                variants="h5regular"
                id="content"
                dangerouslySetInnerHTML={{ __html: announcement.content }}
              ></div>
            </motion.p>
            {announcement.images[0] ? (
              announcement.images[1] ? (
                <motion.div
                  variants={textEffect}
                  initial={'offscreen'}
                  whileInView={'onscreen'}
                  viewport={{ once: true }}
                  className="flex flex-col xl:flex-row items-center justify-center space-y-8 xl:space-y-0"
                >
                  <img
                    src={announcement.images[0].url}
                    className="w-auto h-[400px] px-0 xl:px-8 object-contain"
                  />
                  <img
                    src={announcement.images[1].url}
                    className="w-auto h-[400px] object-contain"
                  />
                </motion.div>
              ) : (
                <motion.div
                  variants={textEffect}
                  initial={'offscreen'}
                  whileInView={'onscreen'}
                  viewport={{ once: true }}
                  className="flex items-center justify-center"
                >
                  <img
                    src={announcement.images[0].url}
                    className="mx-auto w-full xl:w-1/2 h-[400px] object-contain"
                  />
                </motion.div>
              )
            ) : (
              <motion.div
                variants={textEffect}
                initial={'offscreen'}
                whileInView={'onscreen'}
                viewport={{ once: true }}
                className="flex items-center justify-center"
              >
                <img
                  src="https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg"
                  className="mx-auto w-full xl:w-1/2 h-[400px] object-contain"
                />
              </motion.div>
            )}
          </motion.div>
          <div className="flex flex-row space-x-4 lg:space-x-8 py-8 xl:py-16 items-center justify-center">
            {announcementId !== '0' && (
              <Link
                to={`/announcement/announcementDetail/${
                  parseInt(announcementId) - 1
                }`}
                state={location.state}
                onClick={scrollToTop}
              >
                <button className="bg-[#0F1320] text-white px-6 lg:px-8 py-2 rounded-md">
                  Previous
                </button>
              </Link>
            )}

            <Link to={`/announcement`} onClick={scrollToTop}>
              <button className="bg-[#0F1320] text-white  px-6 lg:px-8 py-2 rounded-md">
                Overview
              </button>
            </Link>

            {parseInt(announcementId) !==
              location.state.projects.length - 1 && (
              <Link
                to={`/announcement/announcementDetail/${
                  parseInt(announcementId) + 1
                }`}
                state={location.state}
                onClick={scrollToTop}
              >
                <button className="bg-[#0F1320] text-white  px-10 lg:px-12 py-2 rounded-md">
                  Next
                </button>
              </Link>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default AnnouncementDetails;
