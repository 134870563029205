import { useState } from 'react';
import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import PhoneInput from 'react-phone-input-2';
import { useToast } from '@chakra-ui/react';

import { useRegisterMutation } from '../member/memberApiSlice';

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

import { FormControl, FormLabel, Input, Select } from '@chakra-ui/react';

let initialState = {
  name: '',
  email: '',
  phoneNumber: '',
  country: '',
  dzongkhag: '',
};

const AddMember = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState(initialState);
  const [register, { isLoading }] = useRegisterMutation();

  const validateEmail = email => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleChange = e => {
    if (e.target) {
      setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    } else {
      setFormData(prev => ({ ...prev, phoneNumber: e }));
    }
  };

  const onHandleSubmit = async e => {
    e.preventDefault(e);

    try {
      const { name, email, phoneNumber, dzongkhag, country } = formData;
      const payload = {
        name,
        email,
        phoneNumber,
        dzongkhag,
        country,
      };

      // Form Validation
      if (name && email && phoneNumber && country) {
        // Check if email is valid
        if (!validateEmail(email)) {
          return toast({
            title: 'Form Validation',
            status: 'error',
            description: 'Please enter a valid email address.',
            isClosable: false,
            duration: 2500,
          });
        }

        if (country.toLowerCase() === 'bhutan' && dzongkhag.trim() === '') {
          return toast({
            title: 'Form Validation',
            status: 'error',
            description: 'Please enter a dzongkhag, since you are from bhutan.',
            isClosable: false,
            duration: 2500,
          });
        }
      } else {
        return toast({
          title: 'Form Validation',
          status: 'error',
          description: 'Please fill in all the required fields.',
          isClosable: false,
          duration: 2500,
        });
      }

      const member = await register(payload).unwrap();

      setFormData(initialState);
      toast({
        title: 'Member Registration',
        description:
          'Successfully added member to the waiting list. Please Accept the membership to complete the process.',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
    } catch (error) {
      // Handle different error scenarios
      if (error.data?.message === 'Duplicate Phone Number') {
        toast({
          title: 'Member Registration',
          status: 'error',
          description:
            'A Member with the provided phone number already exists.',
          isClosable: false,
          duration: 2500,
        });
      } else if (error.data?.message === 'Duplicate Email') {
        toast({
          title: 'Member Registration',
          status: 'error',
          description: 'A Member with the provided email already exists.',
          isClosable: false,
          duration: 2500,
        });
      } else {
        toast({
          title: 'Member Registration',
          status: 'error',
          description:
            'An Error Occurred While sending the request. Please try again after some time.',
          isClosable: false,
          duration: 1500,
        });
      }
    }
  };

  return (
    <div>
      <Button leftIcon={<AddIcon />} variant="outline" onClick={onOpen}>
        Add Member
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose} size={'sm'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerHeader>
            <h1>Add Member</h1>
          </DrawerHeader>

          <DrawerBody>
            <form>
              <FormControl id="name" mb={4} isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey' }}
                  isDisabled={isLoading}
                />
              </FormControl>

              <FormControl id="email" mb={4} isRequired>
                <FormLabel>Email Address</FormLabel>
                <Input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey' }}
                  isDisabled={isLoading}
                />
              </FormControl>

              <FormControl id="phoneNumber" mb={4} isRequired>
                <FormLabel>Contact Number</FormLabel>
                <PhoneInput
                  country={'in'}
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  inputProps={{
                    required: true,
                    style: {
                      width: '100%',
                      border: '0.5px solid grey',
                      backgroundColor: 'transparent',
                    },
                  }}
                  name="phoneNumber"
                />
              </FormControl>

              <FormControl id="country" mb={4} isRequired>
                <FormLabel>Country</FormLabel>
                <Input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey' }}
                  isDisabled={isLoading}
                />
              </FormControl>

              {formData.country.toLowerCase() === 'bhutan' && (
                <FormControl id="dzongkhag" mb={4}>
                  <FormLabel>Dzongkhag</FormLabel>
                  <Select
                    placeholder="Select Dzongkhag"
                    name="dzongkhag"
                    value={formData.dzongkhag}
                    onChange={handleChange}
                    style={{ border: '0.5px solid grey' }}
                    isDisabled={isLoading}
                  >
                    <option value="Bumthang">Bumthang</option>
                    <option value="Chhukha">Chhukha</option>
                    <option value="Dagana">Dagana</option>
                    <option value="Gasa">Gasa</option>
                    <option value="Haa">Haa</option>
                    <option value="Lhuntse">Lhuntse</option>
                    <option value="Mongar">Mongar</option>
                    <option value="Paro">Paro</option>
                    <option value="Pema Gatshel">Pema Gatshel</option>
                    <option value="Punakha">Punakha</option>
                    <option value="Samdrup Jongkhar">Samdrup Jongkhar</option>
                    <option value="Samtse">Samtse</option>
                    <option value="Sarpang">Sarpang</option>
                    <option value="Thimphu">Thimphu</option>
                    <option value="Trashigang">Trashigang</option>
                    <option value="Trashi Yangtse">Trashi Yangtse</option>
                    <option value="Trongsa">Trongsa</option>
                    <option value="Tsirang">Tsirang</option>
                    <option value="Wangdue Phodrang">Wangdue Phodrang</option>
                    <option value="Zhemgang">Zhemgang</option>
                  </Select>
                </FormControl>
              )}

              <Button
                type="submit"
                colorScheme="yellow"
                bgColor="#FDBC06"
                onClick={onHandleSubmit}
                className="w-full"
                isLoading={isLoading}
              >
                Add member
              </Button>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default AddMember;
