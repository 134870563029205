import React from 'react'
import Donation from '../features/dashboard/Donation';

const DashboardDonationPage = () => {
  return (
    <div className='h-full'>
      <Donation/>
    </div>
  )
}

export default DashboardDonationPage