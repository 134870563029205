import React, { useState } from 'react';

// Chakra UI
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
} from '@chakra-ui/react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { FormControl, FormLabel, Input, Select } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';

// Icon
import { HiDotsVertical } from 'react-icons/hi';

// Hooks
import { useDisclosure } from '@chakra-ui/react';

// Custom component
import AddOpportunity from './AddOpportunity';
import Pagination from '../dashboard/Pagination';
import Editor from '../../components/Editor';

// API
import {
  useGetOpportunitiesQuery,
  useDeleteOpportunityMutation,
  useUpdateOpportunityMutation,
} from './OpportunityApiSlice';
import { PostImageUploader } from '../../components/ImageUpload';

const DashboardOpportunity = () => {
  // Pagination Logic
  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOpportunity, setSelectedOpportunity] = useState({});
  const [formData, setFormData] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [description, setDescription] = useState('');

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isModelOpen,
    onOpen: onModelOpen,
    onClose: onModelClose,
  } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const {
    isOpen: isAlertOpen,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();
  const cancelRef = React.useRef();

  // Api call
  const {
    data: opportunityList,
    error,
    isLoading,
  } = useGetOpportunitiesQuery(
    { limit, page: currentPage },
    {
      pollingInterval: 5000,
    }
  );

  const [updateOpportunity, { isLoading: isUpdating, isError: updateError }] =
    useUpdateOpportunityMutation();
  const [deleteOpportunity, { isLoading: isDeleting, isError: deleteError }] =
    useDeleteOpportunityMutation();

  // Error Handling
  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        Error: {error.message}
      </div>
    );
  }

  // Pagination
  if (opportunityList?.opportunities.result.length === 0 && !isLoading) {
    if (opportunityList?.opportunities.pageCount) {
      if (
        opportunityList?.opportunities.previous.page === 0 ||
        opportunityList?.opportunities.previous.page === null ||
        opportunityList?.opportunities.previous.page === undefined
      ) {
        setCurrentPage(opportunityList?.opportunities.previous.page);
      } else {
        setCurrentPage(1);
      }
    }
  }

  const handleChange = e => {
    if (e.target) {
      setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const onHandleDelete = async () => {
    if (selectedOpportunity) {
      try {
        await deleteOpportunity({ id: selectedOpportunity._id }).unwrap();
        toast({
          title: 'Opportunity',
          description: 'Opportunity deleted successfully',
          status: 'success',
          duration: 8000,
          isClosable: true,
        });
        onClose();
        onCloseAlert();
      } catch (error) {
        toast({
          title: 'Opportunity',
          description: 'The Operation has failed, please try again later.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
        onCloseAlert();
      }
    }
  };

  const onHandleSubmit = async e => {
    e.preventDefault(e);
    const { title, link, image } = formData;

    if (!title.trim() || !description.trim() || !link.trim()) {
      return toast({
        title: 'Form Validation',
        status: 'error',
        description: 'Please fill all the req uired fields',
        isClosable: false,
        duration: 2500,
      });
    }

    try {
      const updateData = new FormData();
      updateData.append('id', selectedOpportunity._id);
      updateData.append('title', title);
      updateData.append('description', description);
      updateData.append('link', link);
      updateData.append('deleteOldImage', isChecked);

      if (image) {
        updateData.append('image', image);
      }

      const updatedOpportunity = await updateOpportunity({
        id: selectedOpportunity._id,
        data: updateData,
      }).unwrap();
      onModelClose();
      setSelectedOpportunity({});
      setIsChecked(false);
      setDescription('');
      onClose();
      toast({
        title: 'Oppotunity Updation',
        description: 'Successfully updated the opportunity.',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
    } catch (error) {
      setIsChecked(false);
      toast({
        title: 'Opportunity Updation',
        status: 'error',
        description:
          'An Error Occurred While sending the request. Please try again after some time.',
        isClosable: false,
        duration: 2500,
      });
    }
  };

  const handleImageChange = file => {
    setFormData(prev => ({ ...prev, image: file }));
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="w-4/5 mt-10 p-5 border rounded-md mb-10">
        <div className="flex justify-between mb-5 items-center border-b-2 p-5">
          <h1 className="font-medium text-lg">Opportunity List</h1>
          <AddOpportunity />
        </div>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Title</Th>
                <Th>link</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr style={{ height: '50px' }}>
                  <Td colSpan={6} style={{ textAlign: 'center' }}>
                    <Spinner />
                  </Td>
                </Tr>
              ) : opportunityList?.opportunities.result.length === 0 ? (
                <Tr style={{ height: '50px' }}>
                  <Td colSpan={6} style={{ textAlign: 'center' }}>
                    No Content
                  </Td>
                </Tr>
              ) : (
                opportunityList?.opportunities.result.map(opportunity => {
                  return (
                    <Tr key={opportunity._id}>
                      <Td>
                        {opportunity.title.length > 20
                          ? opportunity.title.slice(0, 20) + '...'
                          : opportunity.title}
                      </Td>
                      <Td>
                        {opportunity.link.length > 20
                          ? opportunity.link.slice(0, 20) + '...'
                          : opportunity.link}
                      </Td>
                      <Td>
                        <Button
                          onClick={() => {
                            setSelectedOpportunity(opportunity);
                            onOpen();
                            setFormData(opportunity);
                          }}
                        >
                          <HiDotsVertical />
                        </Button>
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </TableContainer>
        {opportunityList?.opportunities.result.length !== 0 && !isLoading && (
          <div class={'flex justify-end'}>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageCount={opportunityList?.opportunities.pageCount}
            />
          </div>
        )}
      </div>

      <Drawer isOpen={isOpen} onClose={onClose} size={'sm'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="2px" className="text-[#585858]">
            Opportunity
          </DrawerHeader>

          <DrawerBody>
            <div>
              <div className="mb-5">
                <h1 className="text-[#585858] font-semibold mb-1">Title</h1>
                <p className="font-semibold">{selectedOpportunity.title}</p>
              </div>
              <div className="mb-5">
                <h1 className="text-[#585858] font-semibold  mb-1">Link</h1>
                <p className="font-semibold">
                  {
                    <a
                      target="_blank"
                      className="text-indigo-500 underline"
                      href={`${selectedOpportunity.link}`}
                    >
                      {selectedOpportunity.link}
                    </a>
                  }
                </p>
              </div>
              <div className="mb-5">
                <h1 className="text-[#585858] font-semibold  mb-1">
                  Description
                </h1>
                <div
                  className="ql-editor border"
                  variant="h5regular"
                  id={'content'}
                  dangerouslySetInnerHTML={{
                    __html: selectedOpportunity.description,
                  }}
                ></div>
              </div>

              <div className="mb-5">
                <h1 className="text-[#585858] font-semibold  mb-1">Image</h1>
                <img
                  src={
                    selectedOpportunity.image?.url ||
                    'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg'
                  }
                  alt="Opportunity Image"
                />
              </div>
            </div>
          </DrawerBody>

          <DrawerFooter
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              variant="outline"
              mr={3}
              onClick={() => {
                onModelOpen();
                setDescription(selectedOpportunity.description);
              }}
            >
              Edit
            </Button>
            <Button colorScheme="yellow" onClick={onOpenAlert}>
              Delete
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isModelOpen}
        scrollBehavior="inside"
        onClose={() => {
          onModelClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Opportunity</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form>
              <FormControl id="title" mb={4} isRequired>
                <FormLabel>Title</FormLabel>
                <Input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey' }}
                  isDisabled={isUpdating}
                />
              </FormControl>

              <FormControl id="description" mb={4} isRequired>
                <FormLabel>Description</FormLabel>
                <Editor value={description} onChange={setDescription} />
              </FormControl>

              <FormControl id="link" mb={4} isRequired>
                <FormLabel>Link</FormLabel>
                <Input
                  type="text"
                  name="link"
                  value={formData.link}
                  onChange={handleChange}
                  style={{ border: '0.5px solid grey' }}
                  isDisabled={isUpdating}
                />
              </FormControl>
              {selectedOpportunity?.image?.url && (
                <div className="flex gap-2 mb-4">
                  <input
                    type="checkbox"
                    id="deleteOldImage"
                    value={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    isDisabled={isUpdating}
                  />
                  <label htmlFor="deleteOldImage">Delete Old Image</label>
                </div>
              )}
              <FormControl id="Newimage" mb={4}>
                <FormLabel>New Image</FormLabel>
                <PostImageUploader handleImageChange={handleImageChange} />
              </FormControl>
              <Button
                type="submit"
                colorScheme="yellow"
                bgColor="#FDBC06"
                onClick={onHandleSubmit}
                className="w-full"
                isLoading={isUpdating}
              >
                Update Opportunity
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Opportunity
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Cancel
              </Button>
              <Button
                colorScheme={'red'}
                onClick={onHandleDelete}
                ml={3}
                isLoading={isDeleting}
              >
                delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default DashboardOpportunity;
