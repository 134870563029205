import { useState } from 'react';
import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons'
import PhoneInput from 'react-phone-input-2';
import { useToast,Textarea } from '@chakra-ui/react';

import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react';

import { 
    FormControl, 
    FormLabel, 
    Input, 
    Select
} from '@chakra-ui/react';

import { useAddDonationMutation } from './dashApiSlice';

let initialState = {
    amount: 0,
    date: '',
    description: '',
};

const AddDonation = () => {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [formData, setFormData] = useState(initialState);

    const [addDonation, { isLoading, isError, error }] = useAddDonationMutation();

    const handleChange = (e) => {   
        if (e.target) {
          setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        }
    };

    const onHandleSubmit = async (e) => {
        e.preventDefault(e);

        const {amount, date, description} = formData;

        if (!amount || !date) {
            return toast({
                title: 'Form Validation',
                status: 'error',
                description: 'Please fill all the required fields',
                isClosable: false,
                duration: 2500
            });
        }

        if(parseInt(amount) === 0){
            return toast({
                title: 'Form Validation',
                status: 'error',
                description: 'Amount cannot be zero',
                isClosable: false,
                duration: 2500
            });
        }

        try {
            await addDonation(formData).unwrap();
            setFormData(initialState);
            onClose()
            toast({
                title: 'Donation Registration',
                description: 'Successfully added the donation.',
                status: 'success',
                duration: 3000,
                isClosable: false
            });
        } catch (error) {
            toast({
                title: 'Donation Registration',
                status: 'error',
                description: 'An Error Occurred While sending the request. Please try again after some time.',
                isClosable: false,
                duration: 2500
            });
        }
    };
    
    return (
        <div className='mt-5'>
            <Button leftIcon={<AddIcon />} variant='outline' onClick={onOpen}>Add Donation</Button>
            
            <Drawer
            isOpen={isOpen}
            onClose={onClose}
            size={"sm"}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton/>

                <DrawerHeader>
                    <h1>Add Donation</h1>
                </DrawerHeader>

                <DrawerBody>
                    <form>
                        <FormControl id='amount' mb={4} isRequired>
                            <FormLabel>Amount</FormLabel>
                            <Input type='number' name='amount' value={formData.amount} onChange={handleChange} style={{ border: '0.5px solid grey' }}  isDisabled={isLoading}/>
                        </FormControl>
        
                        <FormControl id='date' mb={4} isRequired>
                            <FormLabel>Date</FormLabel>
                            <Input type='date' name='date' value={formData.date} onChange={handleChange} style={{ border: '0.5px solid grey'}}  isDisabled={isLoading}/>
                        </FormControl>
        
                        <FormControl id='description' mb={4}>
                            <FormLabel >Description</FormLabel>
                            <Textarea  type='text' name='description' value={formData.description} onChange={handleChange} style={{ border: '0.5px solid grey' }}  isDisabled={isLoading}/>
                        </FormControl>
                    
                        <Button 
                            type='submit' 
                            colorScheme='yellow' 
                            bgColor="#FDBC06" 
                            onClick={onHandleSubmit}
                            className='w-full'
                            isLoading={isLoading}
                        >
                            Add member
                        </Button>
                    </form> 
                </DrawerBody>
            </DrawerContent>
            </Drawer>
        </div>
    )
}

export default AddDonation