import React from 'react'
import Members from '../features/dashboard/Members';

const DashboardMemberPage = () => {
  return (
    <div className='h-screen'>
      <Members/>
    </div>
  )
}

export default DashboardMemberPage