import React from 'react';
import { useEffect } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { useState } from 'react';
import { Spinner } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
} from '@chakra-ui/react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';

import AddMember from './AddMember';

import { FaMoneyBill } from 'react-icons/fa';
import { BsFillPeopleFill } from 'react-icons/bs';
import { VscRequestChanges } from 'react-icons/vsc';

// Pagination
import Pagination from './Pagination';

// API
import {
  useMemberRegistrationRequestListQuery,
  useDashboardStatisticQuery,
  useMemberRegistrationStatusChangerMutation,
} from './dashApiSlice';

const MemberRegistrationRequestList = () => {
  const toast = useToast();

  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: dashboardStatistic,
    isLoading: isDashboardStatisticLoading,
    isError: isDashboardStatisticError,
    error: dashboardStatisticError,
  } = useDashboardStatisticQuery(
    {},
    {
      pollingInterval: 5000,
    }
  );

  const {
    data: memberList,
    error,
    isLoading,
  } = useMemberRegistrationRequestListQuery(
    { limit, page: currentPage },
    {
      pollingInterval: 5000,
    }
  );

  if (memberList?.pendingMembers.result.length === 0 && !isLoading) {
    if (memberList?.pendingMembers.pageCount) {
      if (
        memberList?.pendingMembers.previous.page === 0 ||
        memberList?.pendingMembers.previous.page === null ||
        memberList?.pendingMembers.previous.page === undefined
      ) {
        setCurrentPage(memberList?.pendingMembers.previous.page);
      } else {
        setCurrentPage(1);
      }
    }
  }

  const [
    changeStatus,
    {
      isLoading: isChangingStatus,
      isSuccess: isChangeStatusSuccess,
      isError: isChangeStatusError,
      error: changeStatusError,
      refetch,
    },
  ] = useMemberRegistrationStatusChangerMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();

  const cancelRef = React.useRef();
  const [selectedMember, setSelectedMember] = useState(null);
  const [action, setAction] = useState(null);

  const onAcceptResponse = async () => {
    setAction('accepted');
    onOpenAlert();
  };

  const onRejectResponse = async () => {
    setAction('reject');
    onOpenAlert();
  };

  const submitResponse = async () => {
    try {
      const response = await changeStatus({
        id: selectedMember._id,
        data: {
          action,
        },
      });

      toast({
        title: 'Membership',
        description: response.data.msg,
        status: 'success',
        duration: 8000,
        isClosable: true,
      });

      onCloseAlert();
      onClose();
    } catch (error) {
      toast({
        title: 'Membership',
        description: 'The Operation has failed, please try again later.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      onCloseAlert();
      onClose();
    }
  };

  // if (isLoading) {
  //   return (
  //     <div className="flex justify-center items-center h-screen">
  //       <Spinner />
  //     </div>
  //   );
  // }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        Error: {error.message}
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex border rounded-md w-4/5 mt-10 justify-center items-center gap-4 py-8">
        <div className="flex justify-center items-center gap-2 border-r-2 px-10">
          <div className="text-4xl p-4 border rounded-full">
            <BsFillPeopleFill />
          </div>
          <div>
            <h1 className="font-normal text-sm text-[#ACACAC]">
              Total Members
            </h1>
            <p className="text-2xl font-bold text-[#333333]">
              {isDashboardStatisticLoading ? (
                <Spinner />
              ) : (
                dashboardStatistic?.membersCount
              )}
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center gap-2 border-r-2 px-10">
          <div className="text-4xl p-4 border rounded-full">
            <VscRequestChanges />
          </div>
          <div>
            <h1 className="font-normal text-sm text-[#ACACAC]">
              Membership Request
            </h1>
            <p className="text-2xl font-bold text-[#333333]">
              {isDashboardStatisticLoading ? (
                <Spinner />
              ) : (
                dashboardStatistic?.membershipRequestCount
              )}
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center gap-2 px-10">
          <div className="text-4xl p-4 border rounded-full">
            <FaMoneyBill />
          </div>
          <div>
            <h1 className="font-normal text-sm text-[#ACACAC]">
              Total Donation
            </h1>
            <p className="text-2xl font-bold text-[#333333]">
              {isDashboardStatisticLoading ? (
                <Spinner />
              ) : (
                dashboardStatistic?.totalDonation
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="w-4/5 mt-10 p-5 border rounded-md mb-10">
        <div className="flex justify-between mb-5 items-center border-b-2 p-5">
          <h1 className="font-medium text-lg">Member Registration Request</h1>
          <AddMember />
        </div>

        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>PhoneNumber</Th>
                <Th>dzongkhag</Th>
                <Th>country</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr style={{ height: '50px' }}>
                  <Td colSpan={6} style={{ textAlign: 'center' }}>
                    <Spinner />
                  </Td>
                </Tr>
              ) : memberList?.pendingMembers.result.length === 0 ? (
                <Tr style={{ height: '50px' }}>
                  <Td colSpan={6} style={{ textAlign: 'center' }}>
                    No Content
                  </Td>
                </Tr>
              ) : (
                memberList?.pendingMembers.result.map(member => {
                  return (
                    <Tr key={member._id}>
                      <Td>
                        {member.name.length > 10
                          ? member.name.slice(0, 10) + '...'
                          : member.name}
                      </Td>
                      <Td>
                        {member.email.length > 10
                          ? member.email.slice(0, 10) + '...'
                          : member.email}
                      </Td>
                      <Td>{member.phoneNumber}</Td>
                      <Td>{member.dzongkhag || 'Nil'}</Td>
                      <Td>{member.country}</Td>
                      <Td>
                        <Button
                          onClick={() => {
                            setSelectedMember(member);
                            onOpen();
                          }}
                        >
                          <HiDotsVertical />
                        </Button>
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </TableContainer>

        {memberList?.pendingMembers.result.length !== 0 && !isLoading && (
          <div class={'flex justify-end'}>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageCount={memberList?.pendingMembers.pageCount}
            />
          </div>
        )}
      </div>

      <Drawer isOpen={isOpen} onClose={onClose} size={'sm'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="2px" className="text-[#585858]">
            Member Details
          </DrawerHeader>

          <DrawerBody>
            {selectedMember && (
              <div>
                <div className="mb-5">
                  <h1 className="text-[#585858] font-semibold mb-1">Name</h1>
                  <p className="font-semibold">{selectedMember.name}</p>
                </div>
                <div className="mb-5">
                  <h1 className="text-[#585858] font-semibold mb-1">Email</h1>
                  <p className="font-semibold">{selectedMember.email}</p>
                </div>
                <div className="mb-5">
                  <h1 className="text-[#585858] font-semibold mb-1">
                    Phone Number
                  </h1>
                  <p className="font-semibold">{selectedMember.phoneNumber}</p>
                </div>
                <div className="mb-5">
                  <h1 className="text-[#585858] font-semibold mb-1">Address</h1>
                  <p className="font-semibold">
                    {selectedMember.dzongkhag} {selectedMember.dzongkhag && ','}{' '}
                    {selectedMember.country}
                  </p>
                </div>
              </div>
            )}
          </DrawerBody>

          <DrawerFooter
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button variant="outline" mr={3} onClick={onRejectResponse}>
              Reject
            </Button>
            <Button colorScheme="blue" onClick={onAcceptResponse}>
              Accept
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {action === 'reject' ? 'Reject Membership' : 'Accept Membership'}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Cancel
              </Button>
              <Button
                colorScheme={action === 'reject' ? 'red' : 'green'}
                onClick={submitResponse}
                ml={3}
                isLoading={isChangingStatus}
              >
                {action === 'reject' ? 'Reject' : 'Accept'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default MemberRegistrationRequestList;
