import MemberDisplay from '../../components/MemberDisplay';
import { motion } from 'framer-motion';
import { GoChevronLeft } from 'react-icons/go';


const MemberPage = () => {
  const textEffect = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: { duration: 1.0, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };
  return (
    <>
     <div className='bg-[#0F1320] min-h-[100px]'>
    </div>
    <div className='min-w-screen min-h-screen bg-[#f0f0f0]'>
      <div className='ml-4 lg:ml-8 mt-8 flex flex-row'>
        <GoChevronLeft className='text-yellow-500 mt-1'/>
        <a href='/'>
          <button className='text-yellow-500 font-bold text-md'>Back to Home</button> 
        </a>
      </div>
      <motion.div 
      className='flex flex-col lg:pt-4 gap-4 lg:gap-12 max-w-7xl mx-auto'>
        <motion.p 
        variants={textEffect}
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: true }}
        className='text-[#484848] text-4xl font-extrabold flex justify-center pt-8 xl:pt-0 '>Core Members</motion.p>
         <motion.div 
         variants={textEffect}
         initial={"offscreen"}
         whileInView={"onscreen"}
         viewport={{ once: true }}
         className='flex flex-col xl:flex-row xl:space-x-4'>
            <hr class="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
            <p className='flex justify-center text-md font-light py-8 text-center md:px-4'>The driving force behind our organization, embodying dedication, expertise, and commitment, shaping our success, fostering community, and amplifying our impact.</p>
            <hr class="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
          </motion.div>
        <motion.div 
        variants={textEffect}
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: true }}
        className='flex flex-col lg:flex-row lg:space-x-8 pb-4 space-y-8 lg:space-y-0 px-8 md:px-16 xl:px-0 '>
          <MemberImage
            src="/president.jpg"
            alt="President"
            name="Kinley Dhuendup Namgyel"
            post="Founder"
            quote="A lost poet and a wanderer who ends up doing a lot of stupid things. However, if there is something stupid that I have not done, it is to be a part of the SZZ family where I have met individuals willing to sacrifice, to endure and to work towards a happy and inclusive society in Bhutan. I feel privileged to be with these individuals with whom I am sure we share a bright future. Started as a social movement, SZZ is an idea which we hope will spread throughout the country. SZZ is from the youths for our country and I guess it resonates with what I wake up and read everyday, a quote from my political hero John F. Kennedy, ‘Ask not what your country can do for you. Ask what you can do for your country.’"
          />

          <MemberImage
            src="/vp.jpg"
            alt="Vice President"
            name="Thuji Wangmo"
            post="Vice President"
            quote="I have keen interest in spirituality, community, human psychology and relationships. I look at Semthuen Zhidhey Zhabtok as a platform where I can further these interests which is why I took the opportunity to contribute as one of the founding members. As a member, I have been truly inspired by the community we have in this group who are all dedicated to helping the society in any capacity possible. The group had achieved a lot in its first year itself! A humbling yet empowering experience this is and I cannot wait to see what awaits us in future"
          />

          <MemberImage
            src="/secratary.jpg"
            alt="Secretary"
            name="Chencho Bidhah"
            post="Secretary"
            quote="An active youth volunteer with Semthuen Zhidhey Zhabtok with strong beliefs in providing social justice and being an agent of change in the society as a volunteer.
            I am enthusiastic, empathetic, and proactive, constantly seeking out opportunities to contribute to meaningful causes and inspire others to join in their efforts for a better world. With this special motive in mind I intend to make Bhutan a better place to live in."
          />

          <MemberImage
            src="/treasurer.jpg"
            alt="Treasurer"
            name="Kinley Rinzin Thinley"
            post="Treasurer"
            quote=" I believe that the world is a beautiful place, but everyone is not as privileged or fortunate as others. We also cannot guarantee that this privilege, fortune, or moment will last forever, for good or bad. So therefore, I believe that helping someone or even making someone smile, even if it is for a moment, is worthwhile. Let's make this world a beautiful place for all of us."
          />
        </motion.div>
        <motion.div className='max-w-7xl mx-auto mb-8'>
          <MemberDisplay/>
        </motion.div>
      </motion.div>
    </div>
    </>
    
  );
};

const MemberImage = ({ src, alt,name,post, quote }) => {
  return (
    <div className='flex flex-col'>
      <div className="relative">
      <img
        src={src}
        alt={alt}
        width={300}
        height={300}
        className="object-cover rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-105 h-full w-full lg:w-[300px] lg:h-[400px] "
      />
      <div className="absolute inset-0 flex flex-col items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out bg-black bg-opacity-50 rounded-lg">
        <p className="text-white text-[10px] px-4 italic">"{quote}"</p>
      </div>
    </div>
    <div className='text-black py-8 space-y-2'>
          <p className='font-semibold'>{name}</p>
          <p className='text-[14px] font-light'>{post}</p>
      </div>
    </div>
  );
};

export default MemberPage;
