import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { GoChevronLeft } from 'react-icons/go';
import { motion } from "framer-motion";
import OpportunityPage from '../../components/OpportunityPage';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const Opportunity = () => {
  const textEffect = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: { duration: 1.2, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };

  return (
    <>
      <motion.div className='bg-[#0F1320] min-h-[100px] min-w-screen'></motion.div>
      <motion.div className='bg-[#F0F0F0] min-h-screen'>
      <div className='ml-4 lg:ml-8 mt-8 flex flex-row'>
        <GoChevronLeft className='text-yellow-500 mt-1'/>
        <a href='/'>
          <button className='text-yellow-500 font-bold text-md'>Back to Home</button> 
        </a>
      </div>
        <motion.div 
          variants={textEffect}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true }}
          className='mb-12 max-w-7xl mx-auto'> 
          <h1 className='flex justify-center text-center mt-8 py-6 text-4xl xl:text-5xl text-black font-extrabold'>Explore a career at Semthuen Zhidhey Zhabtok</h1>
            <div className='flex flex-col xl:flex-row xl:space-x-4'>
              <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
              <p className='flex justify-center text-md font-light py-8 text-center px-4 xl:px-0'>There are jobs-and then there are careers. The chance to do the kind of work that adds up to something meaningful.
                The opportunity to challenge yourself and learn new skills. The prospect of being surrounded by smart, ambitious. motivated people, day-in and day out. That's the kind of work you can expect to do at SZZ. Sound appealing? Come Join us.</p>
              <hr className="hidden xl:flex border-yellow-500 border-2 w-1/5 my-auto"></hr>
            </div>
        </motion.div>


        <motion.div 
          variants={textEffect}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true }}>
            <OpportunityPage/>
        </motion.div>
     
      </motion.div>
    </>
  );
};

export default Opportunity;
