import { createSlice } from '@reduxjs/toolkit';

// This state is for request member list Only
let initialState = {
  members: [],
};

export const dashSlice = createSlice({
  name: 'dash',
  initialState,
  reducers: {
    addMember: (state, action) => {
      state.members.push(action.payload);
    },
    deleteMember: (state, action) => {
      const id = action.payload;
      state.members = state.members.filter(member => member.id !== id);
    },
    setMembers: (state, action) => {
      state.members = action.payload;
    },
  },
});

export const { addMember, deleteMember, setMembers } = dashSlice.actions;

export default dashSlice.reducer;
