import React, { useState } from 'react';
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import { AiFillFileImage } from 'react-icons/ai';

export const PostImageUploader = ({ handleImageChange }) => {
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState('No selected file');
  return (
    <div className="my-5 flex justify-between items-center flex-col">
      <form
        onClick={() => document.querySelector('.input-field').click()}
        className="flex flex-col justify-center items-center border border-black border-dashed h-80 w-96 cursor-pointer rounded"
      >
        <input
          type="file"
          accept="image/*"
          className="input-field"
          hidden
          onChange={({ target: { files } }) => {
            files[0] && setFileName(files[0].name);
            if (files) {
              setImage(URL.createObjectURL(files[0]));
              handleImageChange(files[0]);
            }
          }}
        />
        {image ? (
          <img src={image} width={150} height={150} alt={fileName} />
        ) : (
          <>
            <MdCloudUpload color="#FDBC06" size={68} />
            <p>Browse Images to upload</p>
          </>
        )}
      </form>
      <section className="flex justify-between items-center p-2 mt-2 mb-5 rounded bg-[#e9f0ff] w-full">
        <span className="flex justify-center items-center gap-2">
          <AiFillFileImage color="#1475cf" />
          {fileName}
        </span>
        <MdDelete
          className="cursor-pointer"
          onClick={() => {
            setFileName('No selected File');
            setImage(null);
            handleImageChange('');
          }}
        />
      </section>
    </div>
  );
};
