import React, { useState } from 'react';
import { Button, FormControl, Input, Textarea, useToast } from '@chakra-ui/react';
import { useCreateContactMutation } from './contactApiSlice';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const toast = useToast();
  const [createContact, { isLoading }] = useCreateContactMutation();

  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message } = formData;

    if (!name || !email || !message) {
      toast({
        title: 'Form Validation',
        status: 'error',
        description: 'Please fill in all the required fields.',
        isClosable: true,
      });
      return;
    }

    if (!validateEmail(email)) {
      toast({
        title: 'Email Validation',
        status: 'error',
        description: 'Please enter a valid email address.',
        isClosable: true,
      });
      return;
    }

    // Submit the form data
    try {
      const res = await createContact(formData).unwrap();
      setFormData({
        name: '',
        email: '',
        message: ''
      })

      toast({
        title: 'Form Submission',
        status: 'success',
        description: 'Successfully submitted the form',
        isClosable: true,
      });

    } catch (error) {
      toast({
        title: 'Form Submission',
        status: 'error',
        description: 'An error occured while submitting the form',
        isClosable: true,
      });
    }
  };

  return (
    <>
    <div className='bg-[#0F1320] min-h-[100px] min-w-screen'>
    </div>
    <div 
        className='bg-cover bg-center h-screen'
        style={{ backgroundImage: "url('/bg.svg')" }}>
      <div className='max-w-7xl mx-auto  my-8 py-8 mt-8 px-8 bg-[#ffff]'>
          <h1 className='flex justify-center xl:justify-start text-black text-4xl font-extrabold xl:ml-4'>Get in <span className='text-[#FDBC06]'>Touch</span></h1>
          <p className='text-[#484848] text-sm font-light flex text-center xl:text-start xl:ml-4 mt-4 w-full xl:w-[45%]'>Feel free to contact us anytime. We will get back to you as soon as we can!</p>
          <div className='flex flex-col lg:flex-row py-8 lg:space-x-8 xl:space-x-16 space-y-16 lg:space-y-0 px-8 xl:px-4'>
            <form className='text-black w-full lg:w-1/2 space-y-8' onSubmit={handleSubmit}>
              <FormControl id='userName' isRequired>
                <Input placeholder='Your Name' type='text' name='name' value={formData.name} onChange={handleChange} style={{ border: '0.5px solid grey' }} />
              </FormControl>

              <FormControl id='email' isRequired>
                <Input placeholder='Your Email' type='email' name='email' value={formData.email} onChange={handleChange} style={{ border: '0.5px solid grey' }} />
              </FormControl>

              <FormControl id='message' isRequired>
                <Textarea placeholder='Drop your message' type='text' name='message' value={formData.message} onChange={handleChange} style={{ border: '0.5px solid grey', height: '200px' }} />
              </FormControl>

              <Button type='submit' colorScheme='yellow' bgColor='#FDBC06' className='w-full' isLoading={isLoading}>
                Submit
              </Button>
            </form>
            <div>
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d226804.82084444351!2d89.13827159453122!3d27.349928900000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e195c8235b49b7%3A0x1f610f737b1797a0!2sJigme%20Singye%20Wangchuck%20School%20of%20Law!5e0!3m2!1sen!2sbt!4v1711823040702!5m2!1sen!2sbt"
                width="600"
                height="410"
                style={{ border: 0 }}
                allowFullScreen=""
                className='w-full lg:w-[600px] hidden xl:block'
              ></iframe>
            </div>
          </div>
      </div>
    </div>
    </>
    
  );
};

export default Contact;
