import { current } from '@reduxjs/toolkit';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ currentPage, setCurrentPage, pageCount }) => {
  const prevPageHandler = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPageHandler = () => {
    setCurrentPage(currentPage + 1);
  };

  const changeCurrentPage = e => {
    setCurrentPage(e.selected + 1);
  };

  return (
    <>
      <ReactPaginate
        breakLabel={<span>...</span>}
        nextLabel={
          <button
            className="border-2 border-[#EEEEEE] rounded-md px-2 py-1 bg-[#F5F5F5] text-[#404B52] font-bold cursor-pointer"
            onClick={nextPageHandler}
            disabled={currentPage === pageCount}
          >
            {'>'}
          </button>
        }
        onPageChange={changeCurrentPage}
        pageRangeDisplayed={1}
        pageCount={pageCount}
        previousLabel={
          <button
            className="border-2 border-[#EEEEEE] rounded-md px-2 py-1 bg-[#F5F5F5] text-[#404B52] font-bold"
            onClick={prevPageHandler}
            disabled={currentPage === 1}
          >
            {'<'}
          </button>
        }
        containerClassName="flex items-center justify-center mt-8 mb-4 gap-4"
        pageClassName={`border-2 border-[#EEEEEE] rounded-md px-2 py-1 bg-[#F5F5F5] text-[#404B52] font-bold block cursor-pointer`}
        activeClassName={'text-[#5932EA]'}
      />
    </>
  );
};

export default Pagination;