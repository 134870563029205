import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAddProjectMutation } from './dashApiSlice';
import { AddIcon } from '@chakra-ui/icons';
import Editor from '../../components/Editor';
import { Link } from 'react-router-dom';
import { PostImageUploaders } from '../../components/ImageUploads';

let initialState = {
  announcementName: '',
  date: '',
  content: '',
  images: '',
};
const AddAnnouncement = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = useState('');
  const [announcementName, setAnnouncementName] = useState('');
  const [date, setDate] = useState('');
  const [addAnnouncement, { isLoading, isError, error }] =
    useAddProjectMutation();
  const [images, setImages] = useState(null);
  const [preview, setPreview] = useState(null);
  useEffect(() => {
    localStorage.setItem('projectName', announcementName);
  }, [announcementName]);

  useEffect(() => {
    localStorage.setItem('date', date);
  }, [date]);
  useEffect(() => {
    localStorage.setItem('content', content);
  }, [content]);
  useEffect(() => {
    localStorage.setItem('preview', JSON.stringify(preview));
  }, [preview]);

  const handleImageChange = file => {
    const files = Array.from(file);
    if (files.length > 2) {
      toast({
        title: 'you can only upload up to 2 images',
        status: 'error',
        isClosable: false,
        duration: 3000,
      });
      return;
    }
    setImages(files);
    files.forEach(file => {
      const previewUrls = files.map(file => URL.createObjectURL(file));
      setPreview(previewUrls);
    });
  };

  const onHandleSubmit = async e => {
    e.preventDefault(e);

    if (!announcementName || !date || !content) {
      return toast({
        title: 'Form Validation',
        status: 'error',
        description: 'Please fill all the required fields',
        isClosable: false,
        duration: 2500,
      });
    }
    const formData = new FormData();
    formData.append('projectName', announcementName);
    formData.append('date', date);
    formData.append('content', content);
    formData.append('type', 'announcement');
    if (images) {
      if (images.length > 2) {
        toast({
          title: 'you can only upload up to 2 images',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      images.forEach(image => {
        formData.append('images', image);
      });
    }
    try {
      await addAnnouncement(formData).unwrap();
      onClose();
      toast({
        title: 'Project Listing',
        description: 'Successfully added the project',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
      setAnnouncementName('');
      setDate('');
      setContent('');
      setImages(null);
      setPreview(null);
      localStorage.removeItem('projectName');
      localStorage.removeItem('date');
      localStorage.removeItem('content');
      localStorage.removeItem('preview');
    } catch (err) {
      if (err.status === 400) {
        toast({
          title: err.data.msg,
          status: 'error',
          isClosable: false,
          duration: 3000,
        });
        return;
      }
      toast({
        title: 'Project Listing',
        status: 'error',
        description:
          'An error occurred while sending the request. Please try again after some time.',
        isClosable: false,
        duration: 2500,
      });
    }
  };
  return (
    <div className="mt-5">
      <Button leftIcon={<AddIcon />} variant={'outline'} onClick={onOpen}>
        Add Announcement
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose} size={'lg'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <h1>Add Announcement</h1>
          </DrawerHeader>
          <DrawerBody>
            <form>
              <FormControl id="announcementName" mb={4} isRequired>
                <FormLabel>Announcement Name</FormLabel>
                <Input
                  type="text"
                  name="announcementName"
                  value={announcementName}
                  onChange={e => {
                    setAnnouncementName(e.target.value);
                  }}
                  style={{ border: '0.5px solid grey' }}
                />
              </FormControl>
              <FormControl id="date" mb={4} isRequired>
                <FormLabel>Date</FormLabel>
                <Input
                  type="date"
                  name="date"
                  value={date}
                  onChange={e => {
                    setDate(e.target.value);
                  }}
                  style={{ border: '0.5px solid grey' }}
                />
              </FormControl>
              <FormControl id="content" mb={4} isRequired>
                <FormLabel>Content</FormLabel>
                <Editor value={content} onChange={setContent} />
              </FormControl>
              <PostImageUploaders
                handleImageChange={handleImageChange}
                preview={preview}
                setPreview={setPreview}
              />
              {announcementName && date && content ? (
                <>
                  <Button
                    type="submit"
                    colorScheme="yellow"
                    bgColor={'#FDBC06'}
                    onClick={onHandleSubmit}
                    className="w-full"
                    isLoading={isLoading}
                  >
                    Add Announcement
                  </Button>
                  <Button
                    type="submit"
                    colorScheme="yellow"
                    bgColor={'#FDBC06'}
                    className="w-full"
                    mt={'5px'}
                  >
                    <Link
                      to="/preview/announcement"
                      target="_blank"
                      state={[announcementName, date, content, preview]}
                    >
                      Preview
                    </Link>
                  </Button>
                </>
              ) : null}
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default AddAnnouncement;
