const Footer  = () =>{
    return(<>
    <div className="bg-[#0F1320] pb-10 pt-16">
        <div className="flex flex-col lg:flex-row items-center justify-center text-white text-[12px] lg:space-x-4 space-y-4 lg:space-y-0 ">
            <a href="/member" className="lg:py-2">
                <p className="font-bold underline cursor-pointer">MEMBER</p>
            </a>

            <p className="hidden lg:block text-white px-8 text-opacity-30">/</p>
            
            <a href="/project">
                <p className=" font-bold underline cursor-pointer">PROJECTS</p>
            </a>

            <p className="hidden lg:block text-white px-8 text-opacity-30">/</p>
            
            <a href="/contact">
                <p className=" font-bold underline cursor-pointer">CONTACT</p>
            </a>

            <p className="hidden lg:block text-white px-8 text-opacity-30">/</p>
            
            <a href="/opportunity">
                <p className=" font-bold underline cursor-pointer">OPPORTUNITY</p>
            </a>
            
            <p className="hidden lg:block text-white px-8 text-opacity-30">/</p>

            <a href="/announcement">
                <p className=" font-bold underline cursor-pointer">ANNOUNCEMENT</p>
            </a>
        </div>
        <div className="flex items-center justify-center space-x-4 mt-16 cursor-pointer">
        <img
            src="/Telegram.svg"
            alt="icon"
            className="w-8 h-8"
        />
        <img
            src="/IG.svg"
            alt="icon"
            className="w-8 h-8"
        />
        <a href="https://www.facebook.com/profile.php?id=100064654214762" target="_blank" rel="noopener noreferrer">
            <img
                src="/FB.svg"
                alt="icon"
                className="w-8 h-8"
            />
        </a>
        </div>
        <div className="flex items-center flex-col md:flex-row md:justify-between mt-16 lg:ml-8 lg:mr-8 space-y-8 md:space-y-0 px-4">
            <a href="https://www.rspnbhutan.org/">
                <div className="flex items-center justify-center space-x-2">
                    <p className="font-bold text-[12px] text-white">Sponsored By</p>
                    <img
                    src="/Sponsored.svg"
                    alt="icon"
                    className="w-8 h-8"
                    />
                    <p className="font-light text-[11px] text-white">Royal Society For Protection of Nature</p>
                </div>
            </a>
            

            <a href="https://www.nomindbhutan.com/" target="_blank" rel="noopener noreferrer">
                <div className="flex items-center justify-center space-x-4">
                    <p className="font-bold text-[12px] text-white">Powered By</p>
                    <img
                    src="/Powered.svg"
                    alt="icon"
                    />
                </div>
            </a>
        </div>
    </div>
    </>)
}
export default Footer;
