import React from "react";
import { motion } from "framer-motion";



const AboutUs = () => {

  const textEffect = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: { duration: 1.2, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };

  return (
    <motion.div 
    variants={textEffect}
    initial={"offscreen"}
    whileInView={"onscreen"}
    viewport={{ once: true }}
    className="pb-24 w-full max-w-7xl mx-auto space-y-16 xl:space-y-24 pt-8 xl:pt-24">
      <motion.div 
      variants={textEffect}
      initial={"offscreen"}
      whileInView={"onscreen"}
      viewport={{ once: true }}
      className="p-8 flex flex-col lg:flex-row items-center lg:space-x-8 xl:space-x-24 space-y-8 xl:space-y-0">
        <motion.div className="relative w-full xl:w-1/2 mt-8 lg:mt-0 order-last lg:order-first">
          <img
            src="/img1.jpg"
            width={150}
            height={150}
            alt="work"
            className="w-full h-auto"
          />
        </motion.div>
          <motion.div className="text-black w-full xl:w-1/2 order-first lg:order-last">
            <p className="font-bold text-[#FDBC06] text-[20px] mb-2">Who we are</p>
            <p className="font-bold text-[24px] lg:text-[30px] pb-4">Semthuen Zhidhey Zhabtok is a movement inspired by the vision of His Majesty Druk Gyalpo Jigme Khesar Namgyel Wangchuck.</p>
            <p className="border-b-2 border-[#FDBC06] w-1/6 items-start mb-4"></p>
            <p className="text-[12px] mb-8">Inspired by the visions of His Majesty the Druk Gyalpo for Youths to play a positive role in community inclusivity and development, Semthuen Zhidhey Zhabtok is an idea, formed as a youth group with the hopes that it will spread as a social movement helping individuals throughout Bhutan.
              <br/>
              <br/>
              Join us now as we embark on this journey to leave behind memories of positivity and change. Here, we aspire to turn our members into inspiring leaders and to form meaningful bonds and connections within ourselves and leave behind lasting marks of change, beauty and happiness in our community.</p>
          </motion.div>
     </motion.div>

        {/* <hr class="border-t border-solid border-[#0F1320] w-5/6 mx-auto my-4" /> */}


        <motion.div
        variants={textEffect}
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: true }}  
        className="p-8 flex flex-col lg:flex-row items-center lg:space-x-8 xl:space-x-24 space-y-8 xl:space-y-0 ">
          <motion.div className="text-black text-right w-full xl:w-1/2">
            <p className="font-bold text-[#FDBC06] text-[20px] mb-2">What is our Mission</p>
            <p className="font-bold text-[24px] lg:text-[30px] pb-4">SZZ takes pride in her members who are all dedicated to the idea that we can all take care of each other and uplift each other to conditions which enables everyone to live their fullest potential.</p>
            <p className="border-b-2 border-[#FDBC06] w-1/6 mb-4 ml-auto"></p>
            <p className="text-[12px] mb-8">The primary objective of the group is to create kind, compassionate and empathetic future leaders through the group projects and touch the lives of every possible individual in need who will also then grow up to be proud leaders of our community.</p>
          </motion.div>

          <motion.div className="relative w-full xl:w-1/2">
                <img
                    src="/img4.jpg"
                    alt="work"
                    className="w-full h-auto"
                />
            </motion.div>
        </motion.div>
    </motion.div>
  );
};

export default AboutUs;
